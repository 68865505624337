.menu-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    line-height: 180%;
    letter-spacing: -0.01em;
    color: @dark-turquoise;

    &__item {
        position: relative;
        margin-bottom: 8px;
        padding-bottom: 11px;
    
        &::before {
            content:" ";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            width: 28px;
            background-color: @dark-main;
            margin: 0 auto;
        }

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;

            &::before {
                display: none;
            }
        }
    }
}

.copyright {
    display: inline-block;
}

.policy {
    color: @dark-turquoise;
    text-decoration: none; 
}


.city {
    display: inline-flex;
    align-items: center;
    color: @dark-turquoise;
    text-decoration: none; 
    
    svg {
        margin-left: 5px;
    }
}

@media(min-width: 640px) {
    .city {
        flex-direction: column;

        svg {
            margin: 5px 0 0 0;
        }
    }

    .policy {
        font-size: 13px;
        line-height: 180%;
    }

    .copyright {
        font-size: 13px;
        line-height: 180%;
    }
}

@media(min-width: 640px) { 
    .menu-bottom {
        flex-direction: row;
        align-items: center;

        &__item {
            width: 214px;
            margin-bottom: 0;
            text-align: center;
            padding: 0 25px;

            &::before {
                width: 1px;
                height: 28px;
                background-color: @dark-turquoise;
                right: 0;
                left: inherit;
                margin: 0 auto;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}


@media(min-width: 960px) {
    .menu-bottom {
        &__item {
            width: auto;
            font-size: 16px;
            line-height: 163%;  
        }
    }


    .city {
        flex-direction: row;

        svg {
            margin: 0 0 0 5px;
        }
    }
}