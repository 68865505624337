.top-page {
    position: relative;
    padding: 37px 0 76px;
    background-image: url(../images/common/top-back.svg);
    background-position: -142px bottom;
    background-repeat: no-repeat;
    margin-bottom: -5px;

    &__logo {
        margin: 0 0 20px;
        max-width: 120px;

        img {
            max-width: 100%;
        }
    }

    .ships-line {
        display: none;
    }

    .wave {
        position: absolute;
        bottom: -17px;    
    }

    &__img {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        opacity: 0.5;
        background-position: right;
        background-size: cover;
    }

    .container {
        position: relative;
        z-index: 1;
    }

    &__text {
        font-size: 13px;
        line-height: 180%;
        letter-spacing: -0.01em;
        color: #A7D9DA;
        margin-top: 28px;

        p {
            color: #FEFEFE;
        }
    }

    &__btn {
        margin-top: 25px;
    }

    &--banner {
        padding-bottom: 205px;
        margin-bottom: -117px;
        background-image: none;

        .wave {
            bottom: -64px;
        }
    }

    &--small {
        background-image: none;
        margin-bottom: -4px;
        padding: 32px 0 0;
    }

    &--mini {
        padding-bottom: 0;
        margin-bottom: -56px;
        padding-top: 33px;
        background-image: none;

        .back {
            margin-bottom: 0;
        }
    }

    &--not-wave {
        z-index: 6;
        background-image: none !important;
        padding-bottom: 55px;

        .wave {
            display: none;
        }
    }

    &--grey {
        .back {
            color: @grey-4;

            .svg-icon use {
                fill: @grey-4;
            }
        }

        .bread {
            padding-left: 5%;

            ul {
                li {
                    a {
                        color: @grey-4;

                        &::before {
                            background-color: @grey-4;
                        }
                    }

                    span {
                        color: @grey-4;
                    }
                }
            }
        }
    }
}

@media(min-width: 480px) {
    .top-page {
        padding-bottom: 101px;
        margin-bottom: -29px;
        
        .wave { 
            bottom: 0;
        }

        &--banner {
            padding-bottom: 218px;
            margin-bottom: -129px;
            padding-top: 31px;

            .wave {
                bottom: -30px;
            }
        }


        &__text {
            margin-top: 41px;
        }

        &--small {
            padding-bottom: 0;
            margin-bottom: -26px;
            padding-top: 28px;
        }

        &--mini {
            padding-bottom: 0;
            margin-bottom: -46px;
        }

        &--not-wave {

        }

        &__logo {
            margin-bottom: 10px;
            max-width: 160px;
        }
    }
}

@media(min-width: 640px) {
    .top-page {
        padding-bottom: 85px;
        margin-bottom: -18px;
        padding-top: 31px;

        &--banner {
            padding-bottom: 137px;
            margin-bottom: 77px;
            padding-top: 31px;

            .wave {
                bottom: -151px;
            }
        }

        &__text {
            margin-top: 50px;
        }

        &--small {
            padding-bottom: 0;
            margin: 0;
        }

        &--mini {
            padding-bottom: 0;
            margin-bottom: -53px;
            padding-top: 30px;
        }

        &__logo {
            margin-bottom: 10px;
            max-width: 160px;
        }
    }
}

@media(min-width: 960px) {
    .top-page {
        padding: 47px 0 31px;
        margin-bottom: 0;
        background-image: url(../images/common/wave-line.svg);
        background-position: -390px bottom;

        &--not {
            background-image: none;
        }

        .ships-line {
            display: block;
            position: absolute;
            bottom: -3px;
            z-index: 1;

            &::before {
                width: 2070px;
                height: 495px;
                left: -390px;
                background-image: url(../images/common/ships-back-1200.svg);
            }
        }

        .wave {
            display: none;
        }

        &--banner {
            //padding-bottom: 177px;
            padding-bottom: 120px;
            background-image: none;
            margin-bottom: 28px;

            .wave {
                display: block;
            }
        }

        &__text {
            font-size: 16px;
            line-height: 180%;
        }

        &--small {
            background-image: none;
            padding-bottom: 0;
            padding-top: 44px;
        }

        &--mini {
            padding-top: 28px;
            padding-bottom: 0;
            background-image: none;
            margin-bottom: -49px;


            .bread {
                margin-bottom: 0;
            }
        }

        &--full {
            .top-page__text {
                width: 80%;
                max-width: inherit;
            }
        }

        &--not-wave {
            padding-bottom: 71px;
        }
        
        &--wave-big {
            background-image: url(../images/common/wave-light-960.svg);
            background-position: -120px bottom;

            .wave {
                display: block;
                bottom: -34px;
            }
        }

        &__logo {
            max-width: 190px;
        }
    }
}

@media(min-width: 1200px) {
    @keyframes move-ship {
        0% {
            transform: translateX(calc(~"100% + 12vw"));
        }
        100% { 
            transform: translateX(0);
        }
    }

    .top-page {
        background-position: -185px bottom;

        &--banner {
            padding-bottom: 242px;
            margin-bottom: -86px;

            .wave {
                bottom: -65px;
            }
        }

        &--mini {
            padding-top: 46px;
            margin-bottom: -69px;
            z-index: 2;
            position: relative;

            .bread {
                padding-left: 5%;
            }
        }

        .ships-line {
            &::before {
                left: -185px;
                background-image: url(../images/common/ships-back-1600.svg);
            }
        }
    }
}

@media(min-width: 1600px) {
    .top-page {
        padding-bottom: 78px;

        &__logo {
            max-width: 219px;
            margin: 122px 0 23px;
        }

        &__text {
            font-size: 20px;
            line-height: 163%;
            letter-spacing: -0.01em;
            max-width: 60%;
            margin-top: 41px;
        }

        &--banner {
            //padding-bottom: 251px;
            padding-bottom: 185px;
            margin-bottom: -36px;
        }

        &--small {
            padding-top: 46px;
            padding-bottom: 0;
        }

        &--mini {
            padding-bottom: 0;
            padding-top: 42px;
            margin-bottom: -65px;
            z-index: 2;
        }
        
        &--not-wave {
            padding-bottom: 55px;
        }

        .ships-line {
            &::before {
                left: -75px;
            }
        }
    }
}

@media(min-width: 1920px) {
    .top-page {
        background-position: 75px bottom;
        padding-top: 47px;
        padding-bottom: 79px;

        &__text {
            margin-top: 60px;
            letter-spacing: -0.01em;
        }

        &__btn {
            margin-top: 46px;
        }

        &--banner {
            padding-top: 43px;
            //padding-bottom: 253px;
            padding-bottom: 240px;
            margin-bottom: -78px; 
            
            .wave {
                bottom: -20px;
            }
        }

        &--mini {
            margin-bottom: -68px;
            padding-bottom: 0;
            padding-top: 45px;
        }

        &--not-wave {
            padding-bottom: 54px;
        }

        .ships-line {
            &::before {
                width: 2070px;
                height: 495px;
                left: 0;
                background-image: url(../images/common/ships-back-1200.svg);
            }
        }

        &--wave-big {
            background-position: 0 bottom;

            .wave {
                bottom: -28px;
            }
        }
    }
}
