
.section-fon {
    position: relative;

    .container {
        position: relative;
        z-index: 2;
    }

    .wave {
        position: absolute;
        left: 0;
        top: 51px; 
        z-index: 1;   
    }

    &::before {
        content: " ";
        position: absolute;
        top: -168px;
        width: 864px;
        height: 290px;
        pointer-events: none;
        background-image: url(../images/common/section-fon.svg);
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        background-repeat: no-repeat;
    }
}

@media(max-width: 1200px) {
    .section-fon {
        &::before {
            top: -312px;
            width: 1200px;
            height: 403px;
            background-image: url(../images/common/section-fon-1200.svg);
        }

        .wave {
            height: 120px;
        }
    }
}

@media(min-width: 1600px) {
    .section-fon {
        &::before {
            top: -504px;
            width: 1920px;
            height: 624px;
            background-image: url(../images/common/section-fon-1600.svg);
        }

        .wave {
            height: 120px;
        }
    }
}


@media(min-width: 1920px) {
    .section-fon {
        &::before {
            left: -132px;
            transform: translateX(0);
        }
    }
}