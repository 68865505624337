.menu {
    margin-bottom: 54px;
    flex: 1 1 0;
    
    &>ul {
        padding: 0;
        margin: 0;

        &>li {
            list-style: none;

            &>a {
                font-family: 'Fira Sans Extra Condensed', sans-serif;
                .flex-center-vert();
                height: 45px;
                color: @turquoise;
                font-size: 16px;
                line-height: 150%;
                letter-spacing: .13em;
                text-decoration: none;
                font-variant: small-caps;
                font-weight: normal;

                .svg-icon {
                    display: none;
                }
            }
        }
    }
}

@media(min-width: 480px) {
    .menu {
        &>ul {
            &>li {
                &>a {
                    height: 50px; 
                }
            }
        }
    }
}

@media(min-width: 640px) {
    .menu {
        &>ul {
            &>li {
                &>a {
                    font-size: 16px;
                    line-height: 150%;
                }
            }
        }
    }
}

@media(min-width: 960px) {
    .menu {
        &>ul {
            &>li {
                &>a {
                    font-size: 18px;
                    line-height: 150%;
                    height: 60px; 
                }
            }
        }
    }
}

@media(min-width: 1200px) {
    .menu {
        margin-bottom: 0;
        flex: none;

        &>ul {
            &>li {
                &>a {
                    height: 42px;
                    transition: letter-spacing 0.3s ease;
                }

                &:hover {
                    &>a {
                        letter-spacing: 0.26em;

                        .svg-icon {
                            opacity: 1;
                        }
                    }

                    .dropdown-menu {
                        opacity: 1;
                        pointer-events: auto;
                     }
                }
            }
        }
    }
}

@media(min-width: 1600px) {
    .menu {
        ul {
            &>li {
                &>a {
                    height: 48px;
                }
            }
        }
    }
}


@media(min-width: 1920px) {
    .menu {
        ul {
            &>li {
                
                &>a {
                    .svg-icon {
                        display: block;
                        margin-left: 10px;
                        margin-top: 3px;
                        opacity: 0;
                        transition: opacity 0.3s ease;

                        use {
                            fill: @turquoise;
                        }
                    }
                }
            }
        }
    }
}