@keyframes ship-forever {
    0% {
        transform: translate3d(0,0,0);
    }
    100% { 
        transform: translate3d(-1460px,0,0);
    }
}

.ships-line {
    position: relative;
    height: 164px;
    margin: -88px 0 0 0;

    &::before {
        content: " ";
        position: absolute;
        left: -160px;
        bottom: 3px;
        width: 638px;
        height: 265px;
        background-repeat: no-repeat;
        background-image: url(../images/common/ships-back.svg);
        background-size: cover;
    }

    &__wrap {
        position: relative;
        z-index: 2;
        height: 100%;
        width: 4380px;
        margin-right: -1460px;
        animation: ship-forever 25s linear infinite;
        background-image: url(../images/common/ships.svg);
        background-size: auto 100%;
        background-repeat-x: repeat;
    }

    &--main {
        &::before {
            width: 100vw;
            background-image: url(../images/common/320-1200.png);
            left: -3%;
            height: 41.19vw;
        }

        &::after {
            content: " ";
            position: absolute;
            left: -3%;
            bottom: -17px;
            width: 100vw;
            height: 23px;
            background-image: url(../images/common/wave-ship.png);
        }
    }
}

@media(min-width: 480px) {
    .ships-line {
        &::before {
            left: -100px;
            width: 1060px;
        }

        &--main {
            &::before {
                left: -15vw;
                width: 100vw;
            }

            &::after {
                left: -15vw;
            }
        }
    }
}

@media(min-width: 480px) {
    .ships-line {
        &--main {
            &::before {
                left: -9vw;
            }

            &::after {
                left: -9vw;
                bottom: -16px;
            }
        }
    }
}

@media(min-width: 960px) {
    @keyframes ship-forever {
        0% {
            transform: translate3d(0,0,0);
        }
        100% { 
            transform: translate3d(-2907px,0,0);
        }
    }
    
    .ships-line {
        height: 329px;
        margin: -225px 0 0;

        &::before {
            left: -92px;
            width: 1312px;
            height: 529px;
            bottom: 4px;
            background-image: url(../images/common/ships-back-960.svg);
        }

        &__wrap {
            width: 8721px;
            margin-right: -2907px;
            background-image: url(../images/common/ships.svg);
            animation: ship-forever 50s linear infinite;
        }

        &--main {
            &::before {
                left: -5vw;
                width: 100vw;
                background-image: url(../images/common/320-1200.png);
                height: 41.19vw;
            }

            &::after {
                left: -5vw;
            }
        }
    }
}

@media(min-width: 1200px) {
    @keyframes ship-forever {
        0% {
            transform: translate3d(0,0,0);
        }
        100% { 
            transform: translate3d(-3653px,0,0);
        }
    }

    .ships-line {
        height: 412px;
        margin: -300px 0 0;

        &::before {
            left: -300px;
            width: 2070px;
            height: 495px;
            bottom: 5px;
            background-image: url(../images/common/ships-back-1200.svg);
        }

        &__wrap {
            width: 10959px;
            margin-right: -3653px;
        }

        &--main {
            &::before {
                left: -20vw;
                width: 100vw;
                background-image: url(../images/common/1200-1920.png);
                height: 41.19vw;
            }

            &::after {
                left: -20vw;
                bottom: -14px;
            }
        }
    }
}

@media(min-width: 1600px) {
    .ships-line {
        &::before {
            left: -160px;
        }

        &--main {
            &::before {
                left: -20vw;
                width: 100vw;
                background-image: url(../images/common/1200-1920.png);
                height: 25.8vw;
            }

            &::after {
                left: -20vw;
                bottom: -14px;
            }
        }
    }
}


@media(min-width: 1920px) {
    .ships-line {
        margin: -250px 0 0;
        
        &::before {
            left: -75px;
        }

        &--main {
            &::before {
                height: 495px;
                width: 1920px;
                left: -20vw;
            }

            &::after {
                left: -20vw;
                bottom: -14px;
                width: 100vw;
                background-repeat-x: repeat;
            }
        }
    }
}