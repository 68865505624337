.gallery-slider {
    margin: 0 -5vw;

    .slick-active {
        .gallery-slider__title {
            opacity: 1;
        }
    }

    &__container {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    
    .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent;
        border: 0;
        padding: 0;
        width: 34px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.slick-prev {
            left: 0;
        }

        &.slick-next {
            right: 0;
            
            svg {
                transform: rotate(180deg);
            }
        }

        svg {
            fill: transparent;
            stroke-width: 2;
            stroke: #FFFFFF;
        }
    }

    .slick-slide {
        opacity: 0.2;
        transition: opacity 0.3s ease;

        &.slick-current {
            opacity: 1;
        }
    }

    &__item {
        width: 79vw;
        height: 59vw;
        background-position: center;
        background-size: cover;
    }

    &__title {
        max-width: 89%;
        font-size: 13px;
        line-height: 180%;
        letter-spacing: -0.01em;
        color: @grey-2;
        display: block;
        opacity: 0;
        transition: opacity 0.3s ease;
        padding-left: 10px;
        background: rgba(33, 24, 68, 0.8);
        padding: 4px 0;
        display: inline;

        span {
            line-height: 1;
            font-size: 13px;
            padding: 0 6px;
        }
    }

    &--big {
        .slick-arrow {
            svg {
                use {
                    stroke: @main;
                }
            }
        }
    }
}

.slick-slide {
    outline: 0;
}

@media(min-width: 480px) {
    .gallery-slider {

        .slick-arrow {
            width: 51px;
        }

        .slick-active {
            .gallery-slider__title {
                opacity: 1;
            }
        }

        &__title {
            max-width: 89%;
            font-size: 13px;
            line-height: 180%;
            letter-spacing: -0.01em;
            color: @grey-2;
            display: block;
            opacity: 0;
            transition: opacity 0.3s ease;
            padding-left: 10px;
            background: rgba(33, 24, 68, 0.8);
            padding: 4px 0;
            display: inline;

            span {
                line-height: 1;
                font-size: 13px;
                padding: 0 6px;
            }
        }
    }
}

@media(min-width: 640px) {
    .gallery-slider {

        .slick-arrow {
            width: 48px;

            &.slick-prev {
                left: 10px;
            }
    
            &.slick-next {
                right: 10px;
            }
        }
    }
}

@media(min-width: 960px) {
    .gallery-slider {
        &--big {
            margin: 0;

            .gallery-slider {
                &__item {
                    width: 506px;
                    height: 380px;
                }
            }

            .slick-arrow {
                width: 5vw;
                height: 44px;
                bottom: 0;
                top: initial;
                transform: translateY(0);
                left: 0;
                z-index: 1;

                .inner-block {
                    svg {
                        display: block;
                    }
                }

                &.slick-prev {
                    left: -5vw;
                    background-color: #fff;

                    &:hover {
                        .inner-block {
                            animation: leftArrow 1s linear infinite;
                        }
                    }
                }

                &.slick-next {
                    background-color: @main;

                    &:hover {
                        .inner-block {
                            animation: rightArrow 1s linear infinite;
                        }
                    }

                    .svg-icon {
                        use {
                            stroke: #fff;
                        }
                    }
                }
            }
            
            .slick-slide {
                &.slick-current {
                    &+.slick-slide {
                        opacity: 1;
                        &+.slick-slide {
                            opacity: 1;
                            &+.slick-slide {
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            .slick-list {
                overflow: visible;
            }
        }
    }
}

@media(min-width: 1200px) {
    .gallery-slider {
        &--big {

            .gallery-slider {
                &__item {
                    width: 301px;
                    height: 226px;
                }
            }

            .slick-arrow {
                width: 40px;
                height: 36px;

                &.slick-prev {
                    left: -40px;
                }
            }
        }
    }
}

@media(min-width: 1600px) {
    .gallery-slider {
        &--big {
            .gallery-slider {
                &__item {
                    width: 359px;
                    height: 286px;
                }
            }

            .slick-arrow {
                width: 60px;
                height: 54px;

                &.slick-prev {
                    left: -60px;
                }
            }
        }
    }
}

@media(min-width: 1920px) {
    .gallery-slider {
        &--big {
            .gallery-slider {
                &__item {
                    width: 500px;
                    height: 365px;
                
                }
            }
        }
    }
}