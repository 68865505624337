/*@keyframes move-ship {
    0% {
        transform: translateX(calc(~"100% + 10vw"));
    }
    100% { 
        transform: translateX(0);
    }
}*/

.ship {
    display: none;
}

@media(min-width: 960px) {
    .ship {
        position: absolute;
        bottom: -3px;
        right: 0;
        display: block;
        max-width: 690px;
        transform: translateX(calc(~"100% + 10vw"));
        transition: opacity 0.3s, transform 0.8s ease;

        &.is-active {
           // animation: move-ship 1.1s linear forwards;
        }

        &.is-back {
           opacity: 0;
        }
    }
}

@media(min-width: 1200px) {
    .ship {
        transform: translateX(calc(~"100% + 12vw"));
    }
}



@media(min-width: 1920px) {
   /* @keyframes move-ship {
        0% {
            transform: translateX(calc(~"100% + 22vw"));
        }
        100% { 
            transform: translateX(0);
        }
    }*/

    .ship {
      /*  transform: translateX(calc(~"100% + 22vw"));*/
      /*  right: 22vw;*/
    }
}