.vac-section {
    margin: 0 -5vw -40px;
    position: relative;

    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 100%;
        color: #FFFFFF;
        margin-bottom: 22px;

        br {
            display: none;
        }
    }

    &__link {
        display: none;
        z-index: 5;
    }

    &__item {
        padding: 37px 5%;
        text-align: center;
    }

    &__back {
        display: none;
    }

    &__img {
        display: none;
    }

    &__body {
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    &__text {
        font-weight: 500;
        font-size: 12px;
        line-height: 163%;
        color: #FFFFFF;
        margin-bottom: 26px;
    }

    &__btn {
        position: relative;

        .btn {
            &:hover {
                .main-text {
                    letter-spacing: .28em !important;
                }
            }
        }

        .btn-dote {
            display: none;
        }
    }

}

@media(min-width: 480px) {
    .vac-section {
        margin-bottom: -36px;

        &__title {
            margin-bottom: 32px;
        }

        &__text {
            margin-bottom: 31px;
        }
    }
}


@media(min-width: 640px) {
    .vac-section {
        display: flex;
        position: relative;
        margin-top: -101px;
        height: 900px;
        margin-bottom: -58px;

        &__back {
            top: -72px;
            position: absolute;
            display: block;

            .svg-icon {
                width: 222px;
                height: 656px;
            }
        }

        &__text {
            width: 168px;
            font-size: 12px;
            line-height: 163%;
            position: relative;margin-bottom: 20px;

            br {
                display: none;
            }
        }

        &__title {
            width: 149px;
            font-size: 28px;
            line-height: 120%;
            position: relative;
            margin-bottom: 16px;
        }

        &__btn {
            .btn {
                display: none;
            }

            .btn-dote {
                display: flex;
            }
        }

        &__img {
            display: block;
            position: relative;
            pointer-events: none;
        }

        &__body {
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            text-decoration: none;
        }

        &__item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-right: 18px;
            padding-left: 18px;
            padding-top: 64px;
            width: 50%;
            position: relative;
            background-color: transparent;
            background-size: auto 485px;
            background-repeat: no-repeat;
            background-position: 0 170px;

            &.back-yellow {
                .svg-icon {
                    use {
                        fill: #ECA120;
                    }
                }
            }
            
            &.back-blue {
                .svg-icon {
                    use {
                        fill: #4127A1;
                    }
                }
            }

            &:nth-child(1) {
                align-items: flex-end;
                text-align: right;
                background-position: 83% 187px;
                background-image: url(../images/common/back-table.png);

                .vac-section {
                    &__back {
                        right: 0;
                        transform: scale(-1, 1);
                    }

                    &__body {
                        align-items: flex-end;
                    }

                    &__title {
                        text-align: right;
                    }

                    &__img {
                        margin: 60px 13px 0 0;
                        width: 288px;
                    }

                    &__text {
                        text-align: right;
                    }
                }
            }

            &:nth-child(2) {
  
                text-align: left;
                background-color: #1A113B;
                background-image: url(../images/common/фон-флот.png);

                .vac-section {
                    &__back {
                        left: 0;
                    }

                    &__img {
                        margin: -66px 0 0 18px;
                        width: 288px;
                    }
                }
            }
        }

        
        &__link {
            position: absolute;
            bottom: 204px;
            left: 0;
            right: 0;
            display: flex;
            margin: 0 auto;
            justify-content: center;

            .btn--main {
                padding: 20px 32px;

                .main-text {
                    padding: 20px 25px;
                }
            }
        }
    }
}

@media(min-width: 960px) {
    .vac-section {
        margin-top: -136px;
        
        &__back {
            top: -83px;
        }

        &__title {
            font-size: 36px;
            line-height: 100%; 
            width: 160px;
            margin-bottom: 21px;

            br {
                display: block;
            }
        }

        &__btn {
            .btn {
                opacity: 0;
                display: inline-flex;
                transition: opacity 0.3s ease;
            }
        }

        &__body {
            z-index: 2;
            transition: transform 0.3s, padding 0.3s ease;

            &:hover {
                transform: translateY(-40px);
                padding-bottom: 40px;

                .vac-section {
                    &__btn {
                        .btn {
                            opacity: 1;
                        }

                        .btn-dote {
                            opacity: 0;
                        }
                    }
                }
            }
        }

        &__text {
            font-size: 14px;
            line-height: 165%;
            width: 194px;        
        }

        &__item {
            width: 936px;
            padding-right: 22px;
            padding-left: 22px;
            background-size: 756px auto;
            padding-top: 65px;

            &:nth-child(1) {
            
                background-position: 93% -13px;

                .vac-section {
                    &__img {
                        position: absolute;
                        right: 0;
                        top: 300px;
                        margin: 35px 32px 0 0;
                        width: 345px;
                    }

                    &__btn {
                        .btn-dote {
                            justify-content: flex-end;
                        }
                    }
                }
            }

            &:nth-child(2) {
                background-position: 0 10px;

                .vac-section {
                    &__img {
                        top: 335px;
                        margin: 35px 32px 0 0;
                        width: 345px;
                        width: 383px;
                        margin: -198px 0 0 38px;
                    }
                }
            }
        }

        &__item {
            height: 850px;
            padding-top: 75px;

            &:nth-child(1) {
                .vac-section {
                    &__btn {
                        .btn {
                            background-color: #FFFFFF;
                            color:  #ECA120;
                        }
                    }
                    
                }
            }

            &:nth-child(2) {
                .vac-section {

                    &__btn {
                        justify-content: flex-start;

                        .btn {
                            left: 0;
                            right: inherit;
                            background-color: #FFFFFF;
                            color: #4127A1;
                        }
                    }
                }
            }  
        }

        &__back {
            .svg-icon {
                width: 240px;
                height: 656px;
            }
        }

        &__text {
            font-size: 14px;
            line-height: 163%;
        }

        &__link {
            bottom: 105px;

            .btn {
                font-size: 16px;
                line-height: 19px;
                padding: 18px 40px;
                letter-spacing: .21em;

                .main-text {
                    padding: 18px 30px;
                }
            }
        }
    }
}

@media(min-width: 1200px) {
    .vac-section {
        margin-top: -135px;
        margin-bottom: -125px;

        &__title {
            font-size: 36px;
            line-height: 100%;
            margin-bottom: 9px;
        }

        &__back {
            .svg-icon {
                width: 253px;
                height: 618px;
            }
        }

        &__text {
            width: 209px;
            font-size: 14px;
            line-height: 163%;
        }

        &__btn {
            height: 29px;
        }

        &__link {
            bottom: 171px;

            .btn {
                .main-text {
                    padding: 18px 0;
                }

                &:hover {
                    .main-text {
                        letter-spacing: .30em;
                    }
                }
            }
        }
        


        &__btn {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .btn {
                right: 0;
                top: 0;
                display: inline-block;
                opacity: 0;
                position: absolute;
                transition: opacity 0.3s ease;
            }

            .btn-dote {
                transition: opacity 0.3s ease;
            }
        }

        &__img {
           
            position: absolute;
        }

        &__item {
            height: 850px;
            padding-top: 75px;

            &:nth-child(1) {
                .vac-section {
                    &__img {
                        width: 400px;
                        margin: 0;
                        right: 35px;
                        top: 267px;
                    }

                    &__btn {
                        .btn {
                            background-color: #FFFFFF;
                            color:  #ECA120;
                        }
                    }
                    
                }
            }

            &:nth-child(2) {
                .vac-section {
                    &__img {
                        margin: 0;
                        width: 415px;
                        left: 73px;
                        top: 56px;                   
                    }

                    &__btn {
                        justify-content: flex-start;

                        .btn {
                            left: 0;
                            right: inherit;
                            background-color: #FFFFFF;
                            color: #4127A1;
                        }
                    }
                }
            }  
        }
    }
}

@media(min-width: 1600px) {
    .vac-section {
        margin-bottom: -95px;

        &__title {
            font-size: 42px;
            line-height: 100%;
        }

        &__back {
            top: -128px;

            .svg-icon {
                width: 320px;
                height: 824px;
            }
        }

        &__title {
            width: 183px;
            margin-bottom: 15px;
        }

        &__text {
            width: 233px;
            font-size: 16px;
            line-height: 163%;    
            margin-bottom: 27px;
        }

        &__link {
            bottom: 98px;

            .btn--main {
                border-width: 3px;
            }
        }
        
        &__item {
            padding-right: 30px;
            padding-left: 23px;
            height: 925px;
            padding-top: 87px;

            &:nth-child(1) {
                background-position: 0 0px;
                background-size: 100% auto;

                .vac-section {
                    &__img {
                        right: 25px;
                        top: 330px;
                        width: 446px;
                        margin: 0;
                    }
                }
            }

            &:nth-child(2) {
                background-position: 0 120px;

                .vac-section {
                    &__img {
                        left: 86px;
                        top: 70px;
                        margin: 0;
                        width: 500px;
                    }
                }
            }
        }
    }
}

@media(min-width: 1920px) {
    .vac-section {
        margin-top: -213px;
        margin-bottom: -95px;
        margin-left: 0;
        margin-right: 0;
    }
}