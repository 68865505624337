.content {
    position: relative;
    z-index: 6;

    .ships-line {
        position: absolute;
        bottom: 0;
        left: -16px;
        right: -16px;
    }
}

@media(min-width: 960px) {
    .content {
        display: flex;

        &__main {
            width: 70%;
            padding-right: 25px;
        }

        &--full {
            .content {
                &__main {
                    width: 100%;
                    padding-right: 0;
                }
            }
        }

        &--middle {
            position: relative;

            .cert-line {
                top: 0;
                right: 0;
                position: absolute;
                margin: 0 0 0 0;
                transform: translateY(0) !important;
            }

            .content {
                &__main {
                    width: 77%;
                    padding-right: 0;
                }
            }
        }
    }
}
  
@media(min-width: 1600px) {
    .content {
        &__main {
            padding-right: 85px;
        }

        &--middle {
            .content {
                &__main {
                    width: 80%;
                    padding-right: 0;

                    p {
                        max-width: 80%;
                    }
                }
            }
        }
    }
}