.contact {
    &__title {
        font-size: 20px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: .15em;
        margin-bottom: 15px;
        color: #FEFEFE;
        text-transform: uppercase;
        font-family: 'Fira Sans Extra Condensed', sans-serif;
    }

    &__map {
        &-block {
            height: 250px;
            width: 100%;
            background-color: #fff;

            &>div {
                height: 100%;
                width: 100%;
            }
        }
    }

    &__section {
        margin-bottom: 20px;

        &-title {
            color: #FEFEFE;
            font-weight: 600;
            margin-bottom: 5px;
        }
    }

    &__item {
        margin-bottom: 45px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__value {
        display: flex;
        flex-wrap: wrap;

        span {
            display: inline-block;

            &:first-child {
                color: @grey-4;
                margin-right: 5px;
            }

            &:last-child {
                color: @grey-2;

                a {
                    color: @grey-2;
                    text-decoration: underline;
                }
            }
        }
    }
}

@media(min-width: 640px) {
    .contact {
        &__title {
            margin-bottom: 20px;
        }

        &__body {
            width: 55%;
            float: left;
            padding-right: 5%;
        }

        &__map {
            
            width: 45%;
            float: left;
        }

        &__value {
            font-size: 15px;
        }

        &__item {
            overflow: hidden;
        }

        &__section {
            &-title {
                font-size: 16px;
            }
        }
    }
}


@media(min-width: 960px) {
    .contact {
        &__body {
            width: 33%;
            padding-right: 35px;
        }

        &__item {
            margin-bottom: 55px;
        }

        &__map {
            width: 62%;

            &-block {
                height: 300px;
            }
        }
    }
}

@media(min-width: 1600px) {
    .contact {
        &__value {
            margin-bottom: 16px;
            font-size: 18px;
            line-height: 150%;
            letter-spacing: -0.01em;

            span {
                &:first-child {
                    margin-right: 4px;
                }
            }
        }

        &__item {
            margin-bottom: 150px;
        }

        &__section {
            margin-bottom: 36px;

            &-title {
                font-size: 20px;
                line-height: 163%;
                letter-spacing: -0.01em;
                margin-bottom: 22px;
            }
        }

        &__map {
            &-block {
                height: 400px;
            }
        }
        
        &__title {
            font-size: 30px;
            margin-bottom: 33px;
        }
    }
}