.profile {
    padding-top: 105px;
    padding-bottom: 0;
    background-color: #e0dfe6;

    &__aside {
        display: none;
    }

    &__wrap {
        padding-bottom: 62px;
    }

    &__btn {
        margin-top: -24px;

        .btn {
            width: 100%;
        }
    }

    h1 {
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
        color: @main;
        margin-bottom: 15px;
        letter-spacing: inherit;
    }

    p {
        color: @main;
    }

    ul {
        li {
            color: @main;
        }
    }

    .list-circle {
        li {
            &::before {
                border-color: fade(@main , 50);
            }
        }
    }

    h3 {
        color: @main;
    }

    .warning {
        margin-bottom: 49px;
    }

    &__body {
        margin-top: -15px;
    }
}

@media(min-width: 480px) {
    .profile {
        padding-bottom: 0;

        h1 {
            margin-bottom: 23px;
        }

        .warning {
            margin-bottom: 36px;
        }
    }
}

@media(min-width: 640px) {
    .profile {
        padding-bottom: 0;
        padding-top: 101px;

        h1 {
            margin-bottom: 18px;
        }

        &__btn {
            width: auto;
            display: inline-flex;
            margin-top: 34px;
        }

        .warning {
            margin-bottom: 38px;
        }

        &__wrap {
            padding-bottom: 101px;
        }
    }
}

@media(min-width: 960px) {
    .profile {
        padding-bottom: 0;
        padding-top: 128px;

        h1 {
            font-size: 27px;
            line-height: 127%;
        }

        .warning {
            margin-bottom: 40px;
        }

        &__wrap {
            padding-bottom: 20px;
        }
    }
}

@media(min-width: 1200px) {
    .profile {
        background-color: transparent;
        padding-top: 0;
        padding-bottom: 0;

        h1 {
            margin-bottom: 40px;
        }

        &__wrap {
            font-size: 0;
            overflow: hidden;
            padding-bottom: 0;
            margin-right: -10vw;
        }

        &__body {
            float: left;
            background-color: #e0dfe6;
            width: 49vw;
            padding: 0 40px;
            padding-top: 116px;
            overflow: hidden;

            .warning {
                display: none;
            }
        }
        
        &__btn {
            margin-top: 76px;

            .btn--long {
                padding-left: 47px;
                padding-right: 47px;
            }
        }

        &__aside {
            position: relative;
            float: left;
            display: block;
            width: 30vw;
            padding-right: 35px;
            padding-left: 20px;
            padding-top: 100px;

            &-svg {
                position: absolute;
                top: 85px;
                left: -41px;
                z-index: 2;
            }
        }

        &__body {
            padding-bottom: 90px;
        }
        
        .section--form-contact {
            display: none;
        }
    }
}

@media(min-width: 1600px) {
    .profile {
        margin-bottom: -20px;

        h1 {
            font-size: 35px;
            line-height: 113%;
            margin-bottom: 32px;
        }
        
        &__wrap {
            overflow: hidden;
        }

        &__aside {
            padding-right: 6vw;
        }

        &__body {
            padding-left: 62px;
            padding-right: 62px;
            padding-top: 125px;
            z-index: 1;
            position: relative;
        }
    }
}

@media(min-width: 1920px) {
    .profile {
        &__wrap {
            margin-bottom: -24px;
            margin-right: -11vw;
        }

        .warning {
            max-width: 375px;
        }

        .contact-info {
            max-width: 375px;
        }

        &__btn {
            margin-top: 75px;
        }

        &__body {
            padding-bottom: 115px;
        }
    }
}