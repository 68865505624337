.decor-title {
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: @turquoise;
    font-weight: normal;
    font-family: 'Fira Sans Extra Condensed', sans-serif;
    margin-bottom: 25px;

    &--center {
        text-align: center;
    }
}

@media(min-width: 480px) {
    .decor-title {
        margin-bottom: 43px;
    }
}

@media(min-width: 480px) {
    .decor-title {
        margin-bottom: 50px;
    }
}

@media(min-width: 960px) {
    .decor-title {
        font-size: 23px;
        line-height: 120%;
        margin-bottom: 58px;

        &--center {
            text-align: left;
        }
    }
}

@media(min-width: 1200px) { 
    .decor-title {
        font-size: 30px;
        line-height: 100%;
        margin-bottom: 71px;
    }
}

@media(min-width: 1600px) { 
    .decor-title {
        margin-bottom: 73px;
    }
}

@media(min-width: 1920px) { 
    .decor-title {
        margin-bottom: 80px;
    }
}