.popup {
    position: fixed;
    overflow-y: auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 99990;
    background-color: fade(@main, 90);
    transition: opacity 0.3s ease;
    opacity: 0;
    pointer-events: none;

    &.is-active {
        opacity: 1;
        pointer-events: auto;
    }

    &__top {
        position: relative;
        padding: 54px 20px 0;
    }

    &__container {
        padding: 29px 34px 42px;
        background-image: url(../images/popup/back-480.png);
        background-repeat-y: no-repeat;
        background-position: top center;
    }

    &__top {
        padding: 54px 30px 21px;
    }

    &__wrap {
        width: 100%;
        text-align: center;
    }

    &__info {
        font-size: 14px;
        line-height: 144%;
        color: @dark-turquoise;

        a {
            color: @dark-turquoise;
        }
    }

    &__title {
        letter-spacing: 0.15em;
        text-transform: uppercase;
        font-size: 20px;
        line-height: 120%;
        font-family: 'Fira Sans Extra Condensed';
        color: @turquoise;
        margin-bottom: 27px;
    }
}

.popup .form-ok {

}

@media(min-width: 480px) {
    .popup {
        &__top {
            padding: 76px 50px 36px;
            margin: 0 auto;
        }

        &__container {
            padding: 38px 50px;
            background-image: url(../images/popup/back-640.png);
        }

        &__title {
            margin-bottom: 30px;
        }
    }
}

@media(min-width: 640px) {
    .popup {
        display: flex;
        align-items: center;
        justify-content: center;

        &__top {
            padding: 0 0 37px 0;
            width: 384px;
        }

        &__container {
            padding: 38px 0 38px 0;
        }

        &__body {
            padding: 0 0 37px 0;
            width: 384px;
            margin: 0 auto;
        }

        &__title {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 69px;
            margin-bottom: 7px;
        }
    }
}

@media(min-width: 960px) {
    .popup {
        &__top {
            width: 480px;
            padding: 0 0 30px;
        }

        &__body {
            width: 480px;
        }

        &__container {
            padding: 43px 0;
        }

        &__title {
            font-size: 23px;
            line-height: 120%;
        }
    }
}

@media(min-width: 1600px) {
    .popup {
        &__top {
            width: 550px;
            padding: 0 0 25px;
        }

        &__body {
            width: 550px;
        }
        
        &__info {
            font-size: 16px;
            line-height: 140%;  
        }

        &__container {
            background-image: url(../images/popup/back-1600.png);
        }

        &__title {
            font-size: 30px;
            line-height: 100%;
        }
    }
}