.numeral {
    z-index: 2;

    &__item {
        width: 80%;
        margin: 0 auto;
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__number {
        font-weight: 300;
        text-shadow: 0px 2px 44px rgba(141, 206, 207, 0.3);
        color: @turquoise;
        letter-spacing: -0.03em;
        font-size: 80px;
        line-height: 120%;           
        font-family: 'Fira Sans Extra Condensed', sans-serif;
    }

    &__text {
        color: @turquoise;
        font-size: 13px;
        line-height: 139%;
        padding: 0 18% 0 32px;
        margin-top: -7px;
    }
}

@media(min-width: 480px) {
    .numeral {
        display: flex;
        width: 80%;
        margin: 0 auto;

        &__item {
            width: 100%;
        }

        &__text {
            padding-right: 0;
        }

        &__col {
            width: 50%;
            padding: 0 25px;
        }
        
        &--big {
            width: 100%;
            flex-wrap: wrap;

            .numeral {
                &__item {
                    width: 50%;

                    &:nth-child(odd) {
                        padding-right: 2%;
                    }

                    &:nth-child(even) {
                        padding-left: 2%;
                    }
                }

                &__text {
                    max-width: 98%;
                }

                &__number {
                    font-size: 70px;
                    line-height: 120%;
                    font-weight: normal;
                    letter-spacing: -.065em;
                }
            }
        }
    }
}

@media(min-width: 640px) {
    .numeral {
        &__item {
            margin-bottom: 48px;
        }
    }
}

@media(min-width: 960px) {
    .numeral {
        flex-direction: column;
        width: 100%;

        &__col {
            width: 100%;
            padding-left: 0;
        }

        &__item {
            margin-bottom: 56px;
        }

        &__col {
            margin-bottom: 56px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__number {
            font-size: 91px;
            line-height: 120%;
        }

        &__text {
            font-size: 20px;
            line-height: 139%;
            letter-spacing: -0.01em;
        }

        &--big {
            width: 100%;
            flex-wrap: wrap;
            flex-direction: row;

            .numeral {
                &__item {
                    width: 50%;

                    &:nth-child(odd) {
                        padding-right: 5%;
                    }

                    &:nth-child(even) {
                        padding-left: 5%;
                    }
                }

                &__text {
                    max-width: 98%;
                }

                &__number {
                    font-size: 91px;
                    line-height: 120%;
                    letter-spacing: -0.03em;
                }
            }
        }
    }
}

@media(min-width: 1600px) {
    .numeral {
        &__text {
            padding-left: 0;
            max-width: 82%;
        }
    }
}

@media(min-width: 1600px) {
    .numeral {
        &--big {
            margin: 0 -2%;

            .numeral {
                &__item {
                    width: 33.3%;

                    &:nth-child(even) {
                        padding: 0 2%;
                    }

                    &:nth-child(odd) {
                        padding: 0 2%;
                    }
                }
            }
        }
        
        &--mt-desc {
            margin-top: 101px;
        }
    }
}