.contact-info {
    display: flex;
    flex-direction: column;
    padding: 0 5px;

    &__item {
        order: 4;
        margin-bottom: 12px;


        &:nth-child(4) {
            order: 2;
        }

        &:nth-child(3) {
            order: 1;
        }
    }

    &__title {
        display: inline-block;
        font-size: 13px;
        line-height: 180%;
        letter-spacing: -0.01em;
        color: @grey-4;

        a {
            font-size: 13px;
            line-height: 180%;
            letter-spacing: -0.01em;
            color: #fff;
            text-decoration: underline;
        }
    }

    &__text {
        display: inline-block;

        a {
            font-size: 13px;
            line-height: 180%;
            letter-spacing: -0.01em;
            color: #fff;
            text-decoration: underline;
        }
    }
}

@media(min-width: 960px) {
    .contact-info {
        padding-top: 11px;

        &__title {
            font-size: 16px;
            line-height: 180%;

            a {
                font-size: 16px;
                line-height: 180%;
            }
        }

        &__item {
            margin-bottom: 16px;
        }

        &__text {
            
            a {
                font-size: 16px;
                line-height: 180%;
            }
        }
    }
}


@media(min-width: 1200px) {
    .contact-info {
        margin-left: 13px;

        &__item {
            margin-bottom: 17px;

            &:last-child {
                margin-bottom: 0;
            }

            &:nth-child(3) {
                order: 4;
            }

            &:nth-child(4) {
                order: 4;
            }

            a {
                &:hover {
                    color: @turquoise;
                }
            }
        }

        &__text {
            display: block;

            a {
                &:hover {
                    color: @turquoise;
                }
            }
        }
    }
}