@keyframes rightArrow {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    50% {
        opacity: 1;
    }

    95% { 
       opacity: 0;
       transform: translateX(10px);
    }

    100% { 
        opacity: 1;
    }
}

@keyframes leftArrow {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    50% {
        opacity: 1;
    }

    95% { 
       opacity: 0;
       transform: translateX(-10px);
    }

    100% { 
        opacity: 1;
    }
}