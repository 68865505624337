.form-ok {
    display: none;
    padding-top: 50px;
    
    &.is-active {
        display: block;
    }
    svg {
        margin-bottom: 25px;
    }
    &__title {
        font-size: 22px;
        line-height: 100%;
        font-family: Fira Sans Extra Condensed;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        color: @turquoise;
        margin-bottom: 5px;
    }

    &__text {
        font-size: 13px;
        line-height: 140%;
        color: @turquoise;
    }
}

@media(min-width: 640px) {
    
    .form-ok {
        svg {
            margin-bottom: 25px;
        }

        &__title {
            font-size: 30px;
        }

        &__text {
            font-size: 16px;
        }
    }

}