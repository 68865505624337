.video-block {
    position: relative;
    background-color: black;
    height: 50vw;
    margin: 0 0 23px;
    text-align: center;
  
    video {
      width: 100%;
      height: 100%;
    }

    &__title {
      font-size: 16px;
      line-height: 163%;
      color: #FEFEFE;
      margin-top: 14px;
    }

    &__control {
      cursor: pointer;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
      background-position: center;
      background-size: cover;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: opacity 0.3s ease;

      &.is-hidden {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
  
  @media(min-width: 1200px) {
    .video-block {
      height: 26vw;
    }
  }
  