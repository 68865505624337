.section {
    //padding: 52px 0;
    padding: 37px 0;
    position: relative;
  
    &--main {
      position: relative;
      padding: 0;
      padding-bottom: 70px;
    }

    &--main-about {
        margin-top: -37px;
        padding-bottom: 78px;
        z-index: 2;
    }
  
    &--pb0 {
      padding-bottom: 0!important;
    }

    &--pt0 {
        padding-top: 0 !important;
    }

    &--dark {
        background-color:  @dark-main;
    }

    &--last {
        padding-bottom: 80px;
    }

    &--text {
        z-index: 4;
    }

    &--project {
        padding: 29px 0 45px;
        margin-bottom: 0;
        z-index: 3;
        
        .wave {
            position: absolute;
            top: -60px;
            z-index: 1;
        }
    }

    &--form-contact {
        padding-top: 45px;
    }

    &--bottom {
        padding-bottom: 50px;
    }

    &-z-index {
        z-index: 5;
    }
}

@media(min-width: 480px) { 
    .section {
        &--last {
            padding-bottom: 95px;
        }

        &--text {
            padding-bottom: 40px;
        }

        &--project {
            margin-bottom: 0;
            z-index: 2;
            padding-bottom: 60px;
        }

        &--bottom {
            padding-bottom: 50px;
        }

        &--main-about {
            margin-top: -49px;
        }
    }
}

@media(min-width: 640px) { 
    .section {
        &--text {
            padding-bottom: 60px;
        }

        &--form-contact {
            margin-top: -29px;
            padding-bottom: 55px;
            padding-top: 58px;        
        }

        &--project {
            &::before {
                content:" ";
                background-color: #1a113b;
                position: absolute;
                top: -142px;
                height: 130px;
                left: 0;
                right: 0;
            }
        }

        &--vac-form {
            display: none;
            background-color: transparent;
        }

        &--main-about {
            margin-top: -51px;
        }
    }
}

@media(min-width: 960px) { 
    .section {
        padding: 75px 0;

        &--project {
            &::before {
                display: none;
            }

            .wave {
                position: absolute;
                top: -120px;
                z-index: 1;
                height: 195px;
            }
        }

        &--form-contact {
            margin-top: 39px;
            padding-top: 105px;
            padding-bottom: 140px;
        }

        &--text {
            padding-bottom: 40px;
        }
        
        &--project {
            padding-bottom: 103px;
        }

        &--main-about {
            margin-top: 0;

            .container {
                padding-left: 0;
                padding-right: 0;
            }
        }

        &--last {
            padding-bottom: 115px;
        }

        &--main-fleet {
            margin-top: 45px;
        }
    }
}

@media(min-width: 1200px) { 
    .section {
        &--form-contact {
            padding-top: 120px;
        }

        &--vac {
            .container {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
                margin-left: 5%;
            }
        }

        &--project {
            padding-bottom: 103px;
        }

        &--main-about {
            margin-top: 0;

            .container {
                width: 100%;
                margin-left: 0;
            }
        }

        &--last {
            padding-bottom: 120px;
        }

        &--main-fleet {
            margin-top: 100px;
        }
    }
}

@media(min-width: 1920px) { 
    .section {
        &--main-fleet {
            margin-top: 170px;
        }
    }
}


@media(min-width: 1920px) { 
    .section {
        &--vac {
            .container {
                margin-left: 4%;
            }
        }

        &--project {
            padding-bottom: 103px;
        }
    }
}


