.cert {
    display: flex;
    position: absolute;
    top: 67px;
    right: 5vw;
    z-index: 1;

    &__item {
        width: 36px;
        margin-left: 5px;

        img {
            max-width: 100%;
        }
    }
}

@media(min-width: 480px) {
    .cert {
        &__item {
            width: 48px;
            margin-left: 7px;
        }
    }
}

@media(min-width: 640px) {
    .cert {
        top: 80px;

        &__item {
            width: 58px;
            margin-left: 13px;
        }
    }
}

@media(min-width: 960px) {
    .cert {
        top: 94px;
        right: 4vw;

        &__item {
            width: 73px;
            margin-left: 17px;
        }
    }
}

@media(min-width: 1200px) {
    .cert {
        top: auto;
        bottom: 43px;
        right: 0;
        z-index: 5;

        &__item {
            width: 72px;
        }
    }
}

@media(min-width: 1600px) {
    .cert {
        &__item {
            width: 92px;
            margin-left: 21px;
        }
    }
}