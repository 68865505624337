@keyframes move-forever {
    0% {
        transform: translate3d(0,0,0);
    }
    100% { 
        transform: translate3d(484px,0,0);
    }
}

.wave {
    width: 2000px;
    height: 72px;
    pointer-events: none;

    &__wrap {
        width: 100%;
        height: 100%;
        background-repeat-x: repeat;
        margin-left: -484px;
        background-image: url(../images/common/wave-small.svg);
        animation: move-forever 3s linear infinite;
    }

    &--double {
        height: 175px;
        position: relative;

        .wave {
            &__wrap {
                position: absolute;
                bottom: 0;
                animation-duration: 7s;

                &:first-child {
                    bottom: 40px;
                    opacity: 0.7;; 
                }

                &:nth-child(2) {
                    animation-delay: -3s;
                    animation-duration: 9s;
                }
            }
        }
    }

    &--abs {
        position: absolute;
        top: -31px;  
    }

    &--dark {
        .wave {
            &__wrap {
                background-image: url(../images/common/wave-dark-small.svg);
            }
        }
    }
}

@media(min-width: 960px) {
    @keyframes move-forever {
        0% {
            transform: translate3d(0,0,0);
        }
        100% { 
            transform: translate3d(1500px,0,0);
        }
    }

    .wave {
        width: 4500px;
        

        &__wrap {
            animation: move-forever 5s linear infinite;
            margin-left: -3000px;
            background-image: url(../images/common/wave-middle.svg);
        }

        &--dark {
            .wave {
                &__wrap {
                    background-image: url(../images/common/wave-dark-middle.svg);
                }
            }
        }
    }
}



@media(min-width: 1600px) {
    @keyframes move-forever {
        0% {
            transform: translate3d(0,0,0);
        }
        100% { 
            transform: translate3d(2000px,0,0);
        }
    }

    .wave {
        width: 8000px;
       
        &__wrap {
            margin-left: -2000px;
            background-image: url(../images/common/wave-big.svg);
            animation: move-forever 5s linear infinite;
        }

        &--dark {
            .wave {
                &__wrap {
                    background-image: url(../images/common/wave-dark-middle.svg);
                }
            }
        }

        
    }
}
