.form {
    &__field {
        margin-bottom: 18px;

        &:last-child {
            margin-bottom: 0;
        }

        &.is-error {
            .form {
                &__input {
                    color: #fff;
                    background-color: #FC3838;

                    &::-webkit-input-placeholder {color: #fff}
                    &::-moz-placeholder          {color: #fff}
                    &:-moz-placeholder           {color: #fff}
                    &:-ms-input-placeholder      {color: #fff}
                }

                &__textarea {
                    color: #fff;
                    background-color: #FC3838;

                    &::-webkit-input-placeholder {color: #fff}
                    &::-moz-placeholder          {color: #fff}
                    &:-moz-placeholder           {color: #fff}
                    &:-ms-input-placeholder      {color: #fff}
                }
            }
        }
    }

    &__textarea {
        width: 100%;
        padding: 15px 10px;
        text-align: center;
        border: 0;
        resize: none;
        color: @dark-turquoise;
        background-color: @main;
        font-size: 14px;
        line-height: 163%;
        letter-spacing: -0.01em;
        height: 121px;
        outline: 0;
        transition: color 0.3s ease, background-color 0.3s ease;
        
        &::-webkit-input-placeholder {color:@dark-turquoise; font-family: Fira Sans,sans-serif;}
        &::-moz-placeholder          {color:@dark-turquoise; font-family: Fira Sans,sans-serif;}
        &:-moz-placeholder           {color:@dark-turquoise; font-family: Fira Sans,sans-serif;}
        &:-ms-input-placeholder      {color:@dark-turquoise; font-family: Fira Sans,sans-serif;}
    }

    &__input {
        height: 48px;
        width: 100%;
        padding: 0 10px;
        text-align: center;
        border: 0;
        color: @dark-turquoise;
        background-color: @main;
        font-size: 14px;
        line-height: 163%;
        letter-spacing: -0.01em;
        transition: color 0.3s ease, background-color 0.3s ease;

        &::-webkit-input-placeholder {color:@dark-turquoise; font-family: Fira Sans,sans-serif; letter-spacing: -0.01em;}
        &::-moz-placeholder          {color:@dark-turquoise; font-family: Fira Sans,sans-serif;letter-spacing: -0.01em;}
        &:-moz-placeholder           {color:@dark-turquoise; font-family: Fira Sans,sans-serif;letter-spacing: -0.01em;}
        &:-ms-input-placeholder      {color:@dark-turquoise; font-family: Fira Sans,sans-serif;letter-spacing: -0.01em;}
    }

    &__info {
        font-size: 14px;
        line-height: 140%;
        color: @dark-turquoise;
    }

    &__agree {
        font-size: 12px;
        margin-top: 20px;
        line-height: 140%;
        color: @dark-turquoise;

        br {
            display: none;
        }

        a {
            color: @dark-turquoise;
            text-decoration: underline;
        }
    }
    
    &__btn {
        margin-top: 20px;
    }
}

@media(min-width: 480px) {
    .form {
        &__input {
            font-size: 16px;
            line-height: 163%;
        }

        &__btn {
            margin-top: 14px;

            .btn {
                padding: 14px 49px;
            }
        }
        
        &__agree {
            margin-top: 10px;
            padding: 0;
            font-size: 13px;
            line-height: 140%;
        }
    }
}

@media(min-width: 960px) {
    .form {
        &__agree {
            margin-top: 18px;
        }
        
        &__btn {
            margin-top: 24px;
        }
    }
}

@media(min-width: 1600px) {
    .form {
        &__input {
            font-size: 18px;
            line-height: 163%;
        }

        &__textarea {
            font-size: 18px;
            line-height: 163%;
        }

        &__agree {
            br {
                display: block;
            }
        }
    }
}

