.header {
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
    background-color: fade(@dark-main , 100%);
    z-index: 999999999;

    &__top {
        .flex-center-vert();
        justify-content: space-between;
        position: relative;
        padding-left: 55px;
        padding-right: 12px;
        height: 42px;
    }

    &__info {
        display: flex;
        align-items: center;

        .callback {
            display: none;
        }
    }
}

.index {
    .header {
        background-color: fade(@dark-main , 70%);
    }
}

@media(min-width: 480px) {
    .header {
        &__top {
            height: 48px;
            padding-left: 58px;
        }
    }
}

@media(min-width: 960px) {
    .header {
        &__top {
            height: 60px;
            padding-left: 85px;
            padding-right: 26px;
        }

        &__info {
            .callback {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-left: 58px;

                &__item {
                    margin-bottom: 0;
                    margin-right: 25px;

                    &:last-child {
                        margin-right: 0;
                    }

                    .svg-icon {
                        display: block;
                    }
                }
            }
        }
    }
}

@media(min-width: 1200px) {
    .header {
        background-color: transparent;
        left: inherit;

        &__info {
            .lang {
                display: none;
            }

            .callback {
                margin-left: 0;
            }
        }

        &__top {
            height: 99px;
            justify-content: flex-end;
            padding-left: 0;
        }

        .logo {
            display: none;
        }
    }
}

@media(min-width: 1200px) {
    .index {
        .header {
            background-color: transparent;
        }
    }
    
}


@media(min-width: 1600px) {
    .header {
        padding-right: 14px;
        padding-top: 6px;
    }
}

