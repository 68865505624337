.wrapper {
    overflow: hidden;
    padding-top: 42px;


    &__container {
        position: relative;
        z-index: 4;

        &-main {
            position: relative;

            .footer {
                position: relative;
                z-index: 6;

                .wave {
                    bottom: -41px;
                }
            }

            &::before {
                content:" ";
                position: absolute;
                bottom: 49px;
                left: 0;
                right: 0;
                height: 314px;
                z-index: 5;
                pointer-events: none;
                background: linear-gradient(0deg, #1A113B 0%, rgba(26, 17, 59, 0) 75.35%);
            }
        }
    }

    &__section {
        background-color: @main;
        position: relative;
        z-index: 5;
    }
}

body {
    background-color: @main;
}

.index {
    background-color: transparent;
    .wrapper {
        background-color: @main;
        &__container {
            background-color: #211844;
        }
    }
}
  
@media(min-width: 480px) { 
    .wrapper {
        padding-top: 48px;
    }
}
  
@media(min-width: 960px) { 
    .wrapper {
        padding-top: 60px;
    }
}

@media(min-width: 1200px) { 
    .wrapper {
        padding-top: 0;
        display: flex;
        flex-direction: column;
        min-height: 100vh;

        &__container {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}
