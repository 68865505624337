.cert-line {
    display: flex;
    align-items: center;
    margin: 0 0 25px 0;
    transform: translateY(-35px);

    &__item {
        width: 60px;
        height: 60px;
        box-shadow: 0px 0px 30px rgba(33, 24, 68, 0.8);
        border-radius: 50%;
        margin-right: -10px;

        &:nth-child(1) {
            z-index: 3;
        }

        &:nth-child(2) {
            z-index: 2;
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    &--small {
        margin: 0 0 25px;
        transform: translateY(0);

        .cert-line {
            &__item {
                margin-right: 5px;
            }
        }
    }
}

@media(min-width: 480px) {
    .cert-line {
        margin: 0 0 25px;
        transform: translateY(-44px);

        &--small {
            margin: 0 0 25px;
            transform: translateY(0);
        }
    }
}

@media(min-width: 640px) {
    .cert-line {
        margin: 0 0 -24px;
        transform: translateY(-132px);

        &__item {
            margin-right: -18px;
            width: 80px;
            height: 80px;
        }

        &--small {
            margin: -95px 0 25px;
            transform: translateY(0);

            .cert-line {
                &__item {
                    width: 90px;
                    height: 90px;
                }
            }
        }
    }
}

@media(min-width: 960px) {
    .cert-line {
        margin: 0 0 -94px;
        transform: translateY(-180px);

        &__item {
            width: 108px;
            height: 108px;

            img {
                height: 100%;
                width: 100%;
            }
        }
    }
}


@media(min-width: 1200px) {
    .cert-line {
        margin: 0 0 -14px;
        transform: translateY(-128px);

        &__item {
            width: 120px;
            height: 120px;
        }
    }
}

@media(min-width: 1200px) {
    .cert-line {
        margin: 0 0 -14px;
        transform: translateY(-128px);
        
        &__item {
            width: 147px;
            height: 147px;
        }
    }
}

@media(min-width: 1600px) {
    .cert-line {
        margin: 0 0 -45px -24px;
        transform: translateY(-157px);
    }
}

