.tab {
    &__block {
        display: none;
        padding: 45px 0 0;
    }
}

@media(min-width: 480px) { 
    .tab {
        &__block {
            padding-top: 50px;
        }
    }
}

@media(min-width: 960px) { 
    .tab {
        &__container {
            .menu-tab {
                &__item {
                    display: none;
                }
            }
        }

        &__block {
            padding: 58px 0;

            &.is-active {
                display: block;
            }
        }
    }
}

@media(min-width: 1200px) { 
    .tab {
        &__block {
            padding: 66px 0;
        }
    }
}


@media(min-width: 1600px) { 
    .tab {
        &__block {
            padding: 74px 0;
        }
    }
}

