.s-list {
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-right: -10px;
    }

    &__item {
        font-size: 13px;
        line-height: 180%;
        letter-spacing: -0.01em;
        color: @grey-2;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0 10px 10px 0;
        background-color: #303158;
        border-radius: 24px;
        text-decoration: none;
        padding: 6px 18px;
    }

    &__container {
        margin-bottom: 70px;
    }
}

@media(min-width: 1200px) {
    .s-list {
        margin-right: -12px;

        &__container {
            margin-top: 15px;
            z-index: 1;
            position: relative;
        }

        &__item {
            font-size: 16px;
            line-height: 163%;
            margin-right: 12px;
            margin-bottom: 12px;
            padding: 3px 21px;
            transition: color 0.3s ease;

            &:hover {
                color: @turquoise;
            }
        }
    }
}