.service {
    padding-bottom: 35px;
    display: none;

    &--mobile {
        display: block;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 55px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__img {
        width: 112px;
        height: 112px;
        background-position: 50%;
        background-size: cover;
        margin-bottom: 21px;
        border-radius: 50%;
    }

    &__body {
        text-align: center;
    }

    &__text {
        text-align: center;
        letter-spacing: -.01em;
        margin-bottom: 19px;
        color: @grey-2;
    }

    &__btn {
        .btn {
            width: 100%;
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__name {
        text-align: center;
        text-decoration: none;
        display: inline-flex;
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
        color: #FFFFFF;
        margin-bottom: 18px;
    }
}

@media(min-width: 480px) {
    .service {
        &__btn {
            display: flex;
            margin: 0 -10px;
            align-items: flex-start;

            .btn {
                margin: 0 10px;
                padding-left: 15px;
                padding-right: 15px;

                &:first-child {
                    width: calc(~"41% - 20px");
                }

                &:last-child {
                    width: calc(~"59% - 20px");
                }
            }
        }

        &__item {
            margin-bottom: 48px;
        }

        &__text {
            margin-bottom: 22px;
        }

        &__name {
            margin-bottom: 16px;
        }

        &__img {
            margin-bottom: 26px;
        }
    }
}

@media(min-width: 640px) {
    .service {
        &__btn {
            max-width: 450px;
            margin-left: auto;
            margin-right: auto;
        }

        &__text {
            margin-bottom: 25px;
        }
    }
}


@media(min-width: 960px) {
    .service {
        padding-bottom: 75px;
        padding-top: 127px;
        display: block;

        &--mobile {
            display: none;
        }

        &__item {
            flex-direction: row;
        }

        &__btn {
            margin-left: -10px;
            margin-right: -10px;
        }

        &__name {
            font-size: 27px;
            line-height: 127%;
            margin-bottom: 19px;
        }

        &__img {
            width: 206px;
            height: 206px;
            flex: none;
            margin-bottom: 0;
        }

        &__body {
            text-align: left;
            padding-left: 24px;
        }

        &__text {
            font-size: 16px;
            line-height: 180%;
            text-align: left;
            margin-bottom: 22px;
        }
    }
}

@media(min-width: 1600px) {
    .service {
        &__name {
            font-size: 35px;
            line-height: 113%;
            margin-bottom: 14px;
        }

        &__img {
            width: 243px;
            height: 243px;
        }

        &__text {
            font-size: 20px;
            line-height: 163%;
            margin-bottom: 35px;
        }

        &__item {
            margin-bottom: 85px;
        }

        &__body {
            padding-left: 60px;
        }
    }
}


@media(min-width: 192                       0px) {
    .service {
        &__item {
            margin-bottom: 90px;
        }
    }
}