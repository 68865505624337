.project-small {
    &__item {
        margin-bottom: 66px;

        &:last-child {
            margin-bottom: 0;
        }

        .btn {
            display: none;
        }
    }



    &__img {
        display: block;
        height: 212px;
        flex: none;
        background-position: 50%;
        background-size: cover;
        margin-bottom: 27px;
    
    }

    &__name {
        font-size: 13px;
        line-height: 180%;
        letter-spacing: -0.01em;
        color: @grey-2;
        text-decoration: none;
    }
}

@media(min-width: 480px) {
    .project-small {
        &__item {
            display: flex;
            margin-bottom: 30px;

            .btn {
                display: inline-flex;
            }
        }

        &__body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
        }

        &__img {
            width: 192px;
            height: 143px;
            margin-bottom: 0;
            margin-right: 24px;
        }
    }
}


@media(min-width: 960px) {
    .project-small {
        &__item {
            width: 70%;
        }

        &__img {
            width: 208px;
            height: 155px;
            margin-right: 31px;
        }

        &__name {
            font-size: 16px;
        }
    }
}


@media(min-width: 1200px) {
    .project-small {
        &__name {
            font-size: 20px; 
            transition: color 0.3s ease;

            &:hover {
                color: @turquoise;
            }
        }

        &__img {
            position: relative;
            transition: opacity 0.3s ease;

            &:hover {
                opacity: 0.8;
            }
        }

        &__item {
            margin-bottom: 64px; 
        }
    }
}

@media(min-width: 1600px) {
    .project-small {
        max-width: 99%;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -23px -64px;

        &__item {
            width: 50%;
            padding: 0 23px;
            margin-bottom: 64px;

            &:last-child {
                margin-bottom: 64px;
            }
        }
    }
}