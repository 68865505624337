.map-svg {
    pointer-events: none;
    position: relative;
    width: 340px;
    margin: -66px 0 0 -39px;


    &__geo {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 3;
    }

    &__img {
        display: none;
        max-width: 100%;

        &--1200 {
            display: block;
        }
    }

    &__line {
        position: absolute;
        top: 218px;
        left: 51px;
    }

    svg {
        max-width: 100%;
        height: auto;
    }
}

@media(min-width: 1600px) {
    .map-svg {
        margin: -160px 0 0 -75px;
        width: 496px;
        height: 575px;

        img {
            width: 496px;
            display: block;
            max-width: inherit;
        }


        &__line {
            top: 213px;
            left: 111px;
        }

        &__img {
            &--1200 {
                display: none;
            }

            &--1600 {
                width: 493px;
                display: block;
                overflow: hidden;
                max-width: inherit;
            }
        }
    }
}