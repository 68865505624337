.project-main {
    margin-bottom: 47px;

    &__name {
        font-weight: 600;
        font-size: 18px;
        line-height: 130%;
        color: @grey-2;
        text-decoration: none;
        display: inline-flex;
        margin-bottom: 14px;
    }

    &__item {
        margin-bottom: 47px;
        
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__img {
        position: relative;
        margin-bottom: 15px;

        &-block {
            position: relative;
        }

        &--main {
            display: block;
        }

        &--middle {
            position: absolute;
            top: 100%;
            opacity: 0.2;
            max-width: 50%;
            display: block;
            transition: transform 0.2s, top 0.2s ease;
        }

        &--double {
            display: none;
        }
    }
}

@media(min-width: 480px) {
    .project-main {
        margin-top: -12px;
        margin-bottom: 65px;

        &__body {
            padding-left: 10%;
            padding-right: 10%;
        }

        &__name {
            margin-bottom: 10px;
        }

        &__item {
            margin-bottom: 65px;
        }

        &__img {
            position: relative;
            margin-bottom: 27px;

            &--main {
                max-width: 90%;
            }

            &--middle {
                left: 6%;
            }
        }
    }
}

@media(min-width: 640px) {
    .project-main {
        
        &__body {
            width: 40%;
            padding: 0;
        }

        &__img {
            width: 60%;
            margin-bottom: 0;

            &-block {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            &--main {
                max-width: 100%;
            }

            &--middle {
                right: 100%;
                left: initial;
                top: initial;
                width: 182px;
            }

            &--double {
                display: block;
                max-width: 80%;
            }
        }

        &__item {
            overflow: hidden;

            &:nth-child(odd) {
                .project-main {
                    &__body {
                        float: right;
                    }

                    &__img {
                        padding-left: 5%;
                        float: right;
                    }
                }
            }

            &:nth-child(even) {
                .project-main {
                    &__body {
                        float: left;
                    }

                    &__img {
                        float: left;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        padding-right: 5%;

                        &-block {
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                        }

                       &--middle {
                            left: 100%;
                            right: initial;
                       }
                    }
                }
            }
        }

    }
}

@media(min-width: 960px) {
    .project-main {
        margin-top: -18px;

        &__name {
            font-size: 22px;
            line-height: 130%;
            margin-bottom: 23px;
        }

        &__body {
            width: 40%;
            padding-top: 36px;
            padding-right: 5px;
        }

        &__img {
            width: 60%;

            &--middle {
                width: 267px;
            }
        }
    }
}

@media(min-width: 1200px) {
    .project-main {
        margin-top: -9px;
        margin-bottom: 121px;

        &__body {
            padding-top: 64px;
        }

        &__item {
            margin-bottom: 90px;
        }

        &__name {
            margin-bottom: 24px;
        }

        &__img {
            &--double {
                width: 85%;
            }
        }
    }
}

@media(min-width: 1600px) {
    .project-main {
        margin-bottom: 143px;

        &__name {
            font-size: 29px;
            line-height: 130%;
        }

        &__img {
            &--middle {
                width: 346px;
            }
        }
    }
}

@media(min-width: 1600px) {
    .project-main {
        margin-bottom: 178px;
        
        &__img {
            &--middle {
                width: 412px;
            }
        }

        &__item {
            margin-bottom: 136px;
        }
    }
}

