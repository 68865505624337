.callback {
    display: flex;
    flex-direction: column;

    &__icon {
        width: 30px;
        margin-right: 10px;

        .svg-icon {
            display: block;

            use {
                fill: @turquoise;
            }
        }
    }

    &__text {
        font-family: 'Fira Sans Extra Condensed', sans-serif;
        color: @turquoise;
        font-size: 19px;
        line-height: 163%;
        letter-spacing: .12em;
        font-variant: small-caps;
        font-weight: 300;
        padding-bottom: 3px;
    }

    &__item {
        display: inline-flex;
        align-items: center;
        height: 45px;
        text-decoration: none;
        background-color: transparent;
        border: 0;
        padding: 0;

        span {
            font-weight: 700;
        }

        &--facebook {
            .callback {
                &__icon {
                    .svg-icon {
                        use {
                            stroke: @turquoise;
                            fill: transparent
                        }
                    }
                }
            }
        }

        &--phone {
            .callback {
                &__text {
                    font-weight: 300;
                    font-size: 18px;
                    letter-spacing: 0.03em;
                }
            }
        }
    }

}

@media(min-width: 480px) {
    .callback {
        &__item {
            height: 50px;
        }
    }
}

@media(min-width: 640px) {
    .callback {
        &__text {
            font-size: 21px;
        }

        &__item {
            &--phone {
                .callback {
                    &__text {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

@media(min-width: 960px) {
    .callback {

        &__text {
            font-size: 0;
            color: @main;
            transition: font-size 0.3s, margin 0.3s, padding 0.3s ease;
            padding-bottom: 2px;
            font-weight: normal;
            letter-spacing: 0.03em;
        }

        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: none;
            width: 36px;
            height: 36px;
            margin-right: 0;
            padding-left: 7px;
        }

        &__item {
            display: flex;
            align-items: center;
            justify-content: center;
            height: auto;
            min-width: 36px;
            height: 36px;
            border-radius: 18px;
            transition: background-color 0.3s, box-shadow 0.3s ease;

            &:hover {
                background-color: @turquoise;
                box-shadow: 0px 0px 20px rgba(167, 217, 218, 0.3);    
            
                .svg-icon {
                    use {
                        fill: #211844;
                    }
                }

                .callback {
                    &__text {
                        padding-right: 17px;
                        margin-left: 7px;
                    }
                }
            }

            &--facebook {
                width: 36px;

                .callback {
                    &__icon {
                        padding: 0;
                    }
                }
               
                &:hover {
                    .svg-icon {
                        use {
                            fill: transparent;
                            stroke: #211844;
                        }
                    }

                    .callback {
                        &__text {
                            margin-left: 0;
                            padding-right: 0;
                        }
                    }
                }
            }

            &--phone {
                .callback {
                    &__text {
                        font-size: 0;
                    }
                }

                &:hover {
                    .callback {
                        &__text {
                            font-size: 20px;
                        }
                    }
                }
            }

            &--letter {
                padding-top: 0.5px;

                .callback {
                    &__text {
                        letter-spacing: -.01em;
                        padding-bottom: 1px;
                    }
                }
                
                &:hover {
                    .callback {
                        &__text {
                            font-size: 17px;
                        }
                    }
                }
            }
        }
    }
}