.btn {
    cursor: pointer;
    outline: 0;
    display: inline-block;
    position: relative;
    text-decoration: none;
    border: 0;
    padding: 13px 24px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.28em;
    text-transform: uppercase;
    border: 1px solid transparent;
    font-family: 'Fira Sans Extra Condensed', sans-serif;
    transition: background-color 0.5s, border-color 0.3s ease;
   
    .hidden-text {
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .main-text {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        padding: 13px 0;
        text-align: center;
        transition: letter-spacing 0.5s, color 0.5s ease;
    }
    
    &--b-2 {
        border-width: 2px;
    }

    &--middle {
        padding: 21px 25px;

        .main-text {
            padding: 21px 0;
        }
    }

    &--mini {
        font-size: 12px;
        line-height: 14px;
        padding: 10px 18px;

        .main-text {
            font-size: 12px;
            line-height: 14px;
            padding: 10px 0;
        }
    }

    &--small {
        font-size: 11px;
        line-height: 13px; 
        padding: 7px 14px;

        .main-text {
            padding: 7px 0;
        }
    }

    &--big {
        font-size: 14px;
        line-height: 17px;
    }

    &--long {
        padding: 11px 57px;

        .main-text {
            padding: 11px 0;
        }
    }

    &--turquoise {
        color: @main;
        background-color: @turquoise;
    }
    
    &--out-turquoise {
        color: @dark-turquoise;
        border-color: @dark-turquoise;
        background-color: transparent;
    }

    &--dark {
        color: @dark-turquoise;
        background-color: #211844;
    }

    &--main {
        color: #fff;
        background-color: #4127A1;
    }

    &--dark-main {
        color: #fff;
        background-color: @dark-main;
    }

    &--out-white {
        border-color: #fff;
        color: #fff;
        background-color: transparent;
    }

    &--blue {
        color: #fff;
        background-color: @middle-main;
    }


    &--light {
        background-color: @light-main;
        border-color: @light-main;
        color: @turquoise;
    }

    &--yellow {
        color: #fff;
        background-color: #ECA120;
    }
}

@media(min-width: 640px) {
    .btn {
        &--main {
            font-size: 13px;
            line-height: 16px;
            padding: 21px 40px;

            .main-text {
                padding: 21px 0;
            }
        }

        &--mini {
            padding: 12px 18px;

            .main-text {
                padding: 12px 0;
            }
        }

        &--long {
            padding-top: 20px;
            padding-bottom: 20px;   

            .main-text {
                padding-top: 20px;
                padding-bottom: 20px;   
            }
        }
    }
}

@media(min-width: 992px) {
    .btn {
        &:hover {
            .main-text {
                letter-spacing: .35em;
            }
        }

        &--light {
            &:hover {
                background-color: @turquoise;
                border-color: @turquoise;
                color: @light-main;
            }
        }

        &--dark {
            &:hover {
                color: @turquoise;
            }
        }

        &--main {
            &:hover {
                .main-text {
                    letter-spacing: .25em;
                }
            }
        }
    }
}

@media(min-width: 1200px) {
    .btn {
        &--main {
            font-size: 18px;
            line-height: 22px;
        }
    }
}


@media(min-width: 960px) {
    .btn {
        &--small {
            padding: 9px 14px;
            font-size: 12px;
            line-height: 14px;
        }
    }
}


@media(min-width: 1600px) {
    .btn {
        &--small {
            padding: 12px 24px;
        }
    }
}

.btn-add {
    cursor: pointer;
    font-weight: 500;
    font-size: 13px;
    line-height: 163%;
    letter-spacing: 0.11em;
    text-transform: uppercase;
    color: @grey-1;
    background-color: #A6A3B4;
    border-radius: 5px;
    text-align: center;
    padding: 11px 5px;
    transition: letter-spacing 0.3s, color 0.3s ease;
}

@media(min-width: 1200px) {
    .btn-add {
        &:hover {
            letter-spacing: 0.36em;
        }
    }
}


.btn-more {
    padding: 0 26px;

    .btn {
        width: 100%;
    }
}

@media(min-width: 480px) {
    .btn-more {
        display: flex;
        justify-content: center;

        .btn {
            width: 149px;
        }
    }
}


@media(min-width: 480px) {
    .btn-more {
        .btn {
            width: auto;
            height: auto;
            padding: 14px 34px;
        }
    }
}


@media(min-width: 1600px) {
    .btn-more {
        .btn {
            padding: 21px 50px;
        }
    }
}
