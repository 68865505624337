.project {
    padding-bottom: 45px;

    .btn-more {
        margin-top: -25px;
    }

    &__name {
        font-size: 13px;
        line-height: 180%;
        color: #E0DFE6;
        letter-spacing: -0.01em;
        padding: 0 26px;
    }
    
    &__slider {
        padding: 0 26px;
        margin-bottom: 19px;
    }

    &__section {
        position: relative;

        .wave {
            position: absolute;
            left: -5vw;
            top: 13px;
        }
        
        &::before {
            content: " ";
            position: absolute;
            width: 864px;
            height: 290px;
            pointer-events: none;
            background-image: url(../images/common/wave-light-360.svg);
            left: -195px;
            bottom: calc(~"100% - 85px");
        
        }
    }

    &__item {
        position: relative;
        z-index: 1;
        margin-bottom: 55px;
    }

    &__date {
        position: relative;
        margin-bottom: 40px;
        padding: 0 31px;
        font-family: Fira Sans Extra Condensed;
        font-size: 20px;
        line-height: 120%;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        z-index: 1;
        color: fade(@dark-turquoise , 60);
    }

    &__img {
        display: flex;
        width: 100%;
        height: 153px;
        background-size: cover;
        background-position: center;
    }
}

@media(min-width: 480px) {
    .project {
        &__slider {
            padding: 0 10%;
            margin-bottom: 26px;
        }

        &__date {
            padding: 0 10%;
        }

        &__item {
            margin-bottom: 68px;

        }

        &__name {
            font-size: 16px;
            line-height: 163%;
            padding: 0 10%;
        }

        &__img {
            height: 48vw;
        }

        &__section {
            &::before {
                left: -185px;
            }
        }
    }
}

@media(min-width: 640px) {
    .project {
        &__date {
            font-size: 30px;
            line-height: 120%;
            margin-bottom: 49px;
        }

        &__item {
            margin-bottom: 59px;
        }

        &__img {
            height: 44vw;
        }

        &__slide {
            margin-bottom: -3px;
        }

        &__section {
            .wave {
                left: -5vw;
            }
                       
            &::before {
                width: 936px;
                left: -101px;
                background-image: url(../images/common/wave-light-640.svg);
            }
        }

        &__name {
            font-size: 20px;
            line-height: 163%;
            padding: 0 12%;
        }
    }
}

@media(min-width: 960px) {
    .project {
        .btn-more {
            margin-top: -41px;
        }
        
        &__date {
            font-weight: 500;
            font-family: Fira Sans;
            font-size: 80px;
            line-height: 110%;
            letter-spacing: -0.02em;
            color: @main;
            padding: 0;
            margin-bottom: 46px;
        }

        &__section {
            margin-bottom: 15px;

            .wave {
                top: 43px;
            }

            &::before {
                width: 1368px;
                height: 624px;
                background-image: url(../images/common/wave-light-960.svg);
                left: -92px;
            }
        }

        &__slider {
            padding: 0;
            margin-bottom: 22px;
        }

        &__item {
            margin-bottom: 107px;
        }

        &__wrap {
            display: flex;
        }

        &__img {
            height: 28.5vw;
        }

        &__name {
            padding: 0;
        }

        &__col {
            width: 50%;

            &:nth-child(1) {
                padding-right: 2%;
            }

            &:nth-child(2) {
                padding-left: 2%;
                margin-top: -133px;
            }
        }
    }
}

@media(min-width: 1200px) {
    .project {
        &__date {
            width: 70%;
            padding: 0;
            margin-left: 20%;
            font-size: 100px;
            line-height: 110%;
            margin-bottom: 17px;
        }

        &__col {
            &:nth-child(1) {
                padding-right: 2.5%;
            }

            &:nth-child(2) {
                padding-left: 2.5%;
                margin-top: -107px;
            }
        }

        &__img {
            height: 24.7vw;
        }

        &__item {
            margin-bottom: 67px;
        }

        &__name {
            padding-left: 60px;
        }

        &__wrap {
            width: 70%;
            padding: 0 0 0 2%;
            margin-left: 20%;
        }

        &__section {
            &::before {
                left: 0;
                bottom: calc(~"100% - 132px");
            }

            .wave {
                top: 60px;
            }
        }
    }

    .section--project-list {
        .container {
            width: 100%;
            margin-left: 0;
        }
    }
}

@media(min-width: 1600px) {
    .project {
        &__date {
            font-size: 120px;
            line-height: 110%;
            margin-bottom: 0;
        }

        &__slider {
            margin-bottom: 28px;
        }

        &__img {
            height: 19.7vw;
        }

        &__col {
            &:first-child {
                padding-right: 2%;
                margin-top: -16px;
            }

            &:last-child {
                padding-left: 2%;
            }
        }

        &__section {
            &::before {
                width: 1920px;
                height: 624px;
                left: 0;
                bottom: calc(~"100% - 148px");
                background-position: 0 bottom;
                background-image: url(../images/common/wave-light-1600.svg);
            }

            .wave {
                top: 76px;
            }
        }

        &__wrap {
            padding: 0 7.7% 0 7.2%;
        }
    }
}

@media(min-width: 1920px) {
    .project {
        &__wrap {
            padding: 0 7.2% 0 7.2%;
        }

        &__section {
            .wave {
                top: 70px;
                height: 80px;
            }
        }

        &__item {
            margin-bottom: 74px;
        }

        &__slider {
            margin-bottom: 22px;
        }

        &__name {
            padding-left: 73px;
        }

        &__col {
            &:first-child {
                margin-top: 0;
            }
        }

        .btn-more {
            margin-top: 0;
        }
    }
}