.scroll-line {
    display: none;   
}

@media(min-width: 1920px) {
    .scroll-line {
        display: block;
        position: fixed;
        right: 33px;
        bottom: 178px;
        z-index: 7;

        &__name {
            font-weight: 300;
            font-size: 13px;
            line-height: 110%;
            letter-spacing: .14em;
            color: #6d97a8;
            transform: rotate(90deg);
            transform-origin: left top 0;
            position: absolute;
            white-space: nowrap;
            bottom: -28px;
            left: 33px;

            span {
                padding-left: 15px;
            }
        }

        &__body {
            display: flex;
            flex-direction: column;
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 317px;
        }

        &__prev {
            width: 1px;
            background-color: @dark-turquoise;
            transition: height 0.2s ease-out;
        }

        &__next {
            width: 1px;
            flex-grow: 1;
            background-color: @dark-turquoise;
        }

        &__img {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: none;
            width: 50px;
            height: 50px;
        }
    }

}