.menu-tab {
    display: none;

    &__item {
        display: flex;
        align-items: center;
        height: 48px;
        font-weight: 600;
        font-size: 17px;
        line-height: 150%;
        color: @dark-turquoise;
        transition: color 0.3s ease;
        text-decoration: none;
        border-bottom: 2px solid #303158;

        &--bottom {
            margin-top: 45px;
            border-top: 0;
        }

        .svg-icon {
            margin-right: 15px;
            transition: transform 0.3s ease;

            use {
                fill: transparent;
                stroke: @dark-turquoise;
                stroke-width: 2;
            }
        }

        &.is-active {
            color: @turquoise;

            .svg-icon {
                transform: rotate(180deg);

                use {
                    stroke: @turquoise;
                }
            }
        }
    }
}

@media(min-width: 480px) {
    .menu-tab {
        &__item {
            height: 60px;
            font-size: 17px;
            line-height: 150%;
            border-bottom-width: 3px;

            &--bottom {
                margin-top: 50px;
            }
            
            .svg-icon {
                margin: 0 19px 0 11px;
            }
        }
    }
}

@media(min-width: 640px) {
    .menu-tab {
        &__item {
            .svg-icon {
                margin: 0 25px 0 20px;
            }
        }
    }
}

@media(min-width: 960px) {
    .menu-tab {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 14px;

        &__item {
            position: relative;
            margin-right: 35px;
            height: 78px;
            font-size: 19px;
            line-height: 150%;
            border: 0;
            cursor: pointer;

            &:last-child {
                margin-right: 0;
            }

            span {
                position: relative;
                overflow: hidden;
                padding-bottom: 2px;
            }

            .svg-icon {
                display: none;
            }

            &:hover {
                color: #a7d9da;

                .menu-tab {
                    &__line {
                        opacity: 1;

                        .svg-icon {
                            use {
                                fill: #A7D9DA;
                            }
                        }
                    }
                }
            }

            &::before {
                content: " ";
                position: absolute;
                top: -18px;
                left: -25px;
                right: -25px;
                background-image: url(../images/ship/line-1.svg);
                display: block;
                height: 6px;
                background-repeat: no-repeat;
                opacity: 0;
                transition: opacity .3s ease;
                background-size: 100% auto;
            }

            &.is-active {
                .menu-tab {
                    &__line {
                        opacity: 0;
                    }
                }

                &::before {
                    opacity: 1;
                }
            }


            &:nth-child(2) {
                &::before {
                    background-image: url(../images/ship/line-2.svg);
                }
            }

            &:nth-child(3) {
                &::before {
                    background-image: url(../images/ship/line-3.svg);
                }
            }

            &:nth-child(4) {
                &::before {
                    background-image: url(../images/ship/line-4.svg);
                }
            }
        }
        
        &__line {
            position: absolute;
            bottom: 0;
            opacity: 0.3;
            transition: opacity 0.3s ease;

            .svg-icon {
                display: block;
                margin: 0;
                transform: rotate(0) !important;

                use {
                    stroke-width: 0;
                    fill: #6D97A8;
                }
            }
        }

        &--bottom {
            margin-top: 0;

            .menu-tab {
                &__item {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }    
}

@media(min-width: 1600px) {
    .menu-tab {
        margin-top: 10px;

        &__item {
            font-size: 24px;
            line-height: 150%;
            margin-right: 56px;

            &::before {
                top: -15px;
                left: -55px;
                right: -55px;
            }
        }

        &--bottom {
            margin-top: -23px;
            margin-bottom: 15px;        
        }
    }
} 
