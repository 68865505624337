.gallery-img {
    display: none;
}

@media(min-width: 480px) {
    .gallery-img {

        img {
            display: block;
        }

        &__item {
            position: relative;

            &:nth-child(2) {
                margin-right: -10vw;
            }
        }

        &__top {
            position: absolute;
            left: 0;
            top: 0;
        }

        &__title {
           
            max-width: 89%;
            font-size: 13px;
           // line-height: 180%;
           line-height: 1.77;
            letter-spacing: -0.01em;
            color: @grey-2;
            display: block;
            padding-left: 10px;
            background: #37345e;
            padding: 4.145px 0;
            display: inline;
            display: -moz-inline-stack;

            span {
                line-height: 1;
                font-size: 13px;
                padding: 0 6px;
            }
        }
    }
}

@media(min-width: 640px) {
    .gallery-img {

        .slick-arrow {
            width: 48px;

            &.slick-prev {
                left: 10px;
            }
    
            &.slick-next {
                right: 10px;
            }
        }
    }
}

@media(min-width: 960px) {
    .gallery-img {
        display: block;
    }
}