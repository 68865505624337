.dropdown-menu {
    display: none;
    position: fixed;
    min-width: 353px;
    left: 320px;
    z-index: 90000;
    top: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    padding: 40px  0 40px 40px;
    background-color: fade(@dark-main, 90%);
    transition: opacity 0.6s ease;

    .hidden-text {
        opacity: 0;
    }

    .main-text {
        height: 48px;
        left: 0;
        top: 0;
        right: 0;
        position: absolute;
        transition: letter-spacing 0.3s ease;
    }
}

@media(min-width: 1200px) {
    .dropdown-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        left: 100%;

        ul {
            padding: 0;
            margin: 0;

            li {
                list-style: none;

                a {
                    position: relative;
                    white-space: nowrap;
                    display: inline-flex;
                    padding-right: 40px;
                    height: 48px;
                    font-size: 14px;
                    line-height: 150%;
                    color: #a7d9da;
                    letter-spacing: .21em;
                    text-decoration: none;
                    font-family: Fira Sans Extra Condensed,sans-serif;
                    text-transform: uppercase;
                    
                    &:hover {
                        .main-text {
                            letter-spacing: 0.26em;
                        }
                    }
                }
            }
        }
    }
}

@media(min-width: 1920px) {
    .dropdown-menu {
        left: 320px;
    }
}