.ui-datepicker {
    background: #f4f3f6;
    box-shadow: 0 2px 30px rgba(28,28,28,.07);
    border-radius: 5px;
    width: 260px;
    margin-left: 0;
    border: 0!important;
    font-family: Fira Sans,sans-serif;
    top: inherit !important;
    left: inherit !important;
    right: 0;

    .ui-datepicker-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 140.62%;
        letter-spacing: -0.01em;
        color: @main;
    }    

    .ui-datepicker-header {
        padding: 8px 0;
    }
}

.ui-datepicker table {
    thead {
        th {
            font-weight: 600;
            font-size: 13px;
            line-height: 154%;
            color: @main;
            text-transform: uppercase;
            padding: 2px 0;
            padding-bottom: 9px;
        }
    }

    tbody {
        td {
            font-weight: 300;
            font-size: 16px;
            line-height: 163%;
            text-align: center;
            text-align: center;


            a {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                border-radius: 50%;
                width: 22px;
                height: 22px;
                padding: 0;
                font-weight: 300;
                font-size: 16px;
                line-height: 163%;
                color: #1A113B;
                transition: background-color 0.3s ease;
            }

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                border-radius: 50%;
                width: 22px;
                height: 22px;
                font-weight: 300;
                font-size: 16px;
                padding: 0;
                line-height: 163%;
                color: #1A113B;
                transition: background-color 0.3s ease;  
            }
        }
    }
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
    border: 0;
    font-weight: 300;
    background: none;
    color: #1A113B;
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
    background: #2C2154;
    color: #fff
}

.ui-state-disabled, .ui-widget-content .ui-state-disabled, .ui-widget-header .ui-state-disabled {
    opacity: 1;
    color: #A6A3B4;
    filter: Alpha(Opacity=1);
}

.ui-datepicker-header {
    background-color: transparent;
    border: 0;
}



.ui-datepicker-unselectable {
    span {
        color: #A6A3B4 !important;
    }
}
