.people {
    &__name {
        font-weight: 600;
        font-size: 32px;
        line-height: 130%;
        color: #fff;
        margin-bottom: 4px;
    }

    &__item {
        margin-bottom: 54px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__avatar {
        position: relative;
        display: inline-flex;
        margin-bottom: 81px;
    }

    &__quotes {
        position: relative;
        width: 178px;
        color: @light-main;
        font-style: italic;
        font-weight: 500;
        font-size: 12px;
        line-height: 130%;
        padding: 14px 18px;
        background-color: @turquoise;

        &-wrap {
            position: absolute;
            right: -32px;
            bottom: -52px;
        }

        .svg-icon {
            position: absolute;
            left: -21px;
            top: -36px;
            width: 67px;
            height: auto;
            stroke-width: 2;
            stroke: @turquoise;
            fill: transparent;
        }
    }

    &__img {
        width: 198px;
        height: 264px;
        background-position: center;
        background-size: cover;
    }

    &__post {
        font-size: 13px;
        line-height: 180%;
        letter-spacing: -0.01em;
        color: @grey-4;
        margin-bottom: 13px;
    }

    &__history {

        &-item {
            margin-bottom: 24px;
            
            &:last-child {
                margin-bottom: 0;
            }
        }

        &-date {
            color: @grey-4;
            font-weight: 300;
            font-size: 18px;
            line-height: 163%;
            letter-spacing: 0.32em;
            text-transform: uppercase;
            margin-bottom: 6px;
            font-family:Fira Sans Extra Condensed,sans-serif;
        }

        &-text {
            font-size: 13px;
            letter-spacing: -0.01em;
            line-height: 180%;
            color: #FFFFFF;
        }
    }
}

@media(min-width: 480px) {
    .people {
        &__img {
            width: 252px;
            height: 336px;
        }

        &__avatar {
            margin-bottom: 62px;
        }

        &__item {
            margin-bottom: 64px;
        }

        &__post {
            margin-bottom: 33px;
        }

        &__quotes {
            width: 234px;
            padding: 20px 27px;
            font-size: 13px;
            line-height: 150%;

            &-wrap {
                right: -44px;
                bottom: -49px;
            }

            .svg-icon {
                top: -50px;
                left: -26px;
                width: 85px;
            }
        }

        &__post {
            font-size: 16px;
        }
    }
}

@media(min-width: 640px) {
    .people {
        &__img {
            width: 348px;
            height: 464px;
        }

        &__avatar {
            margin-bottom: 91px;
            margin-left: 6%;
        }

        &__item {
            margin-bottom: 7px;
        }

        &__post {
            font-size: 13px;
            margin-bottom: 31px;
        }

        &__name {
            margin-bottom: 9px;
        }

        &__quotes {
            width: 302px;
            padding: 37px 30px;
            font-size: 18px;
            line-height: 142%;

            &-wrap {
                right: -60px;
                bottom: -75px;
            }

            .svg-icon {
                top: -58px;
                left: -21px;
                width: 100px;
            }
        }

        &__history {
            &-item {
                display: flex;
                position: relative;

                &::before {
                    content:" ";
                    position: absolute;
                    width: 1px;
                    top: 31px;
                    bottom: -19px;
                    left: 23px;
                    background-color: @light-main;
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }

            &-date {
                width: 79px;
                flex: none;
            }
        }
    }
}

@media(min-width: 960px) {
    .people {
        &__img {
            width: 348px;
            height: 464px;
        }

        &__avatar {
            margin-bottom: 91px;
            margin-left: 0;
        }

        &__item {
            display: flex;
            align-items: flex-start;
            margin-bottom: 147px;
        }

        &__post {
            font-size: 16px;
            margin-bottom: 38px;
        }

        &__name {
            font-size: 35px;
            line-height: 113%;
            margin-bottom: 8px;
        }

        &__body {
            padding-left: 98px;
            padding-top: 59px;
        }

        &__quotes {
            width: 320px;
            padding: 22px 40px;
            font-size: 20px;
            line-height: 142%;

            &-wrap {
                right: -60px;
                bottom: -85px;
            }

            .svg-icon {
                top: -72px;
                left: -39px;
                width: 120px;
            }
        }

        &__history {
            &-item {
                margin-bottom: 56px;

                &::before {
                    bottom: -50px;
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }

            &-text {
                font-size: 16px;
                line-height: 163%;
                padding-top: 2px;
            }

            &-date {
                width: 69px;
                flex: none;
            }
        }
    }
}

@media(min-width: 1600px) {
    .people {
        &__name {
            font-size: 42px;
            line-height: 113%;
        }

        &__item {
            margin-bottom: 138px;

            &:nth-child(odd) {
                padding-left: 8%;
            }

            &:nth-child(even) {
                padding-right: 8%;
            }
        }

        &__img {
            width: 450px;
            height: 600px;
        }

        &__quotes {
            width: 360px;
            padding: 42px;

            &-wrap {
                right: -36px;
                bottom: -41px;
            }
        }
    }
}


@media(min-width: 1920px) {
    .people {
        &__name {
            font-size: 42px;
            line-height: 113%;
            margin-bottom: 1px;
        }

        &__quotes {
            padding: 35px 42px;

            &-wrap {
                right: -46px;
                bottom: -69px;
            }
        }

        &__history {
            &-item {
                margin-bottom: 41px;

                &::before {
                    left: 26px;
                }
            }
        }

        &__post {
            margin-bottom: 72px;
            font-size: 20px;
            line-height: 163%;
        }

        &__body {
            padding-left: 86px;
            padding-top: 93px;
        }

        &__item {
            margin-bottom: 114px;

            &:nth-child(odd) {
                padding-left: 13.5%;
            }

            &:nth-child(even) {
                padding-right:  13.5%;
            }
        }
    }
}

