.list-circle {
    &--mb0 {
        margin-bottom: 0 !important;
    }

    li {
        position: relative;
        padding-left: 24px;

        &::before {
            position: absolute;
            left: 0;
            top: 8px;
            margin: 0 !important;
            width: 8px !important;
            height: 8px !important;
            border-radius: 50%;
            opacity: 0.5;
            border: 2px solid #FFFFFF;
            background-color: transparent !important;
            box-sizing: border-box;
        }
    }
}

@media(min-width: 960px) {
    .list-circle {
        li {
            padding-left: 33px;
            margin-bottom: 19px !important;

            &:last-child {
                margin-bottom: 0 !important;
            }
        }
    }
}

@media(min-width: 1600px) {
    .list-circle {
        li {
            margin-bottom: 17px !important;

            &::before {
                top: 13px;
            }
        }
    }
}


