.back {
    display: inline-flex;
    align-items: center;
    font-size: 11px;
    line-height: 163%;
    font-family: 'Fira Sans Extra Condensed', sans-serif;
    letter-spacing: 0.11em;
    color: @dark-turquoise;
    text-transform: uppercase;
    text-decoration: none;
    margin-bottom: 42px;
  
    .svg-icon {
      margin-right: 18px;
  
      use {
        fill: @dark-turquoise;
      }
    }
}

@media(min-width: 480px) {
    .back {
        margin-bottom: 62px;
    }
}

@media(min-width: 640px) {
    .back {
        margin-bottom: 53px;
    }
}



@media(min-width: 960px) {
    .back {
        display: none;
    }
}