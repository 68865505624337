.list-check {
    padding: 0;
    margin: 0;

    &--mb0 {
        margin-bottom: 0 !important;
    }

    li {
        display: flex;
        margin-bottom: 10px !important;

        &::before {
            display: none !important;
        }

        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    &__icon {
        width: 30px;
        flex: none;
        margin-top: 1px;
        margin-right: 19px;

        .svg-icon {
            width: 100%;
            height: auto;

            use {
                fill: @turquoise;
            }
        }
    }

    &__text {
        font-weight: 500;
        font-size: 16px;
        line-height: 163%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }
}

@media(min-width: 480px) {
    .list-check {
        li {
            margin-bottom: 24px !important;
        }
    }
}

@media(min-width: 640px) {
    .list-check {
        li {
            align-items: center;
            margin-bottom: 17px !important;
        }

        &__text {
            font-size: 18px;
        }

        &__icon {
            margin-top: 0;
            margin-right: 16px;
            width: auto;
        }
    }
}

@media(min-width: 1200px) {
    .list-check {
        &__text {
            font-size: 20px;
            line-height: 163%;
        }
    }
}

@media(min-width: 1600px) {
    .list-check {
        &--double {
            width: 70vw;
            display: flex;
            flex-wrap: wrap;

            li {
                width: 50%;
            }
        }
    }
}