.footer {
    position: relative;
    z-index: 3;

    &__wrap {
        position: relative;
        padding-top: 54px;
        padding-bottom: 41px;
    }

    

    .wave {
        position: absolute;
        bottom: 23px;
        height: 196px;
    }
}

@media(min-width: 640px) {
    .footer {
        &__wrap {
            padding-top: 54px;
            padding-bottom: 48px;
        }

        .wave {
            bottom: -33px;
        }
    }
}

@media(min-width: 960px) {
    .footer {
        &__wrap {
            padding-top: 47px;
            padding-bottom: 47px;
        }
    }
}

@media(min-width: 1600px) { 
    .footer {
        .wave {
            bottom: -50px;
        }
    }
}

@media(min-width: 1920px) { 
    .footer {
        &::before {
            height: 495px;
            bottom: 73px;
        }
    }
}
