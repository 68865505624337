.date-line {
   display: none;
}

@media(min-width: 1200px) {
    .date-line {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 310px;
        right: 30px;
        z-index: 90;

        &__item {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 13px;
            line-height: 110%;
            transition: color 0.3s ease;
            font-family: Fira Sans Extra Condensed;
            color: @dark-turquoise;

            &:hover {
                color: @turquoise;
            }

            &:after {
                content:" ";
                display: block;
                width: 1px;
                height: 21px;
                background-color: fade(@dark-turquoise, 30);
                margin: 3px 0;
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }

            &.is-active {
                color: @turquoise;
            }
        }
    }
}

@media(min-width: 1600px) {
    .date-line {
        top: 350px;
        right: 50px;
    }
}