.container {
    padding: 0 5%;
}


@media(min-width: 1200px) {
    .container {
        width: 70%;
        padding: 0;
        margin-left: 20%;
     /*   width: 840px*/
    }
}


@media(min-width: 1600px) {
    .container {
      /*  width: 1120px;*/
    }
}

@media(min-width: 1920px) {
    .container {
       /* width: 1342px;*/
    }
}




