.select {
    width: 100%;

    &__container {
        display: flex;
        flex-direction: column;
        margin-top: 25px;

        .select {
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .jq-selectbox {
        width: 100%;

        &.opened {
            .jq-selectbox__select {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
            .jq-selectbox__trigger {
                transform: rotate(180deg);
            }
        }
    }

    .jq-selectbox__trigger {
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(../images/common/select-arrow.png);
        transition: transform 0.3s ease;
    }

    .jq-selectbox__trigger-arrow {
        border: 0;
        position: relative;
        top: inherit;
        right: inherit;
    }

    .jq-selectbox__select {
        height: 42px;
        border: 0 !important;
        outline: 0 !important;
        background: none;
        background-color: #fff;
        box-shadow: none;
        padding: 0 10px;
        font-family: Fira Sans,sans-serif;
        transition: border-color 0.3s,border-radius 0.3s ease;
    }

    .jq-selectbox__select-text {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100% !important;
        font-weight: 300;
        font-size: 16px;
        line-height: 163%;
        color: #211844;
        border: 0 !important;
        outline: 0 !important;
    }

    .jq-selectbox__dropdown {
        margin: 0;
        border: 0;
        box-shadow: none;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        font-family: Fira Sans,sans-serif;

        &::before {
            content: "";
            top: -42px;
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;
            pointer-events: none;
            box-shadow: 0 2px 30px rgba(28,28,28,.07);
        }

        ul {
            margin-bottom: 0;

            li {
                font-weight: 300;
                font-size: 16px;
                line-height: 163%;
                color: #211844;
                padding: 2px 10px !important;
                margin-bottom: 0;
                letter-spacing: -0.01em;
                transition: background-color 0.3s ease;
                

                &::before {
                    display: none;
                }

                &:hover {
                    background-color: fade(@grey-2, 50);
                }

                &.selected {
                    background-color: fade(@grey-2, 50);
                }
            }
        }
    }

    .jq-selectbox__trigger {
        width: 42px;
        border-left: 0;
    }

    &--dark {
        .jq-selectbox {
            &__select {
                border-radius: 0;
                height: 36px;
                padding: 0 18px;
                background-color: #303158;

                &-text {
                    color: @turquoise;
                    font-size: 15px;
                    line-height: 150%;
                    text-shadow: none;
                    font-family: Fira Sans Extra Condensed;
                }
            }

            &__dropdown {
                ul {
                    background-color: #A7D9DA;

                    li {
                        font-family: Fira Sans Extra Condensed;
                        font-size: 15px;
                        line-height: 150%;
                        padding: 7px 18px!important;
                        color: #211844;
                        letter-spacing: 0.05em;

                        &.selected {
                            background-color: fade(#fff, 10);
                        }

                        &:hover {
                            background-color: fade(#fff, 10);
                        }
                    }
                }
            }

            &__trigger {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 36px;
                background-image: url(../images/common/select-arrow-black.svg);
            }
        }
    }
}


@media(min-width: 480px) {
    .select {
        &__container {
            margin-top: 41px;
        }

        &--dark {
            .jq-selectbox {
                &__select {
                    height: 48px;
                    padding: 0 17px;

                    &-text {
                        font-size: 18px;
                        line-height: 150%;
                        letter-spacing: 0.05em;
                    }
                }

                &__dropdown {
                    ul {
                        li {
                            letter-spacing: inherit;
                            font-size: 18px;
                            line-height: 150%;
                            padding: 10px 17px!important;
                            letter-spacing: 0.05em;
                        }
                    }
                }

                &__trigger {
                    width: 48px;
                }
            }
        }
    }
}

@media(min-width: 640px) {
    .select {
        &__container {
            display: flex;
            flex-direction: row;
            margin-top: 47px;

            .select {
                width: 284px;
                margin-right: 8px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

@media(min-width: 960px) {
    .select {
        &--type {
            width: 394px;
        }

        &__container {
            margin-top: 52px;

            .select {
                width: 268px;
                margin-right: 23px;

                &--type {
                    width: 394px;
                }
            }
        }
    }
}

@media(min-width: 1200px) {
    .select {
        &--type {
            width: 348px;
        }

        &__container {
            margin-top: 52px;
            
            .select {
                width: 318px;
                margin-right: 30px;

                &--type {
                    width: 348px;
                }
            }
        }
    }
}

@media(min-width: 1600px) {
    .select {
        .jq-selectbox__select-text {
            font-weight: 300;
            font-size: 20px;
            line-height: 163%;
        }

        .jq-selectbox__select {
            padding: 0 17px;
        }

        .jq-selectbox__dropdown {
            ul {
                li {
                    padding-left: 17px !important;
                    padding-right: 17px !important;
                    font-size: 20px;
                    line-height: 163%;
                }
            }
        }

        &--type {
            width: 434px;
        }

        &__container {
            margin-top: 30px;
            
            .select {
                width: 276px;
                margin-right: 23px;

                &--type {
                    width: 434px;
                }
            }
        }

        &--dark {
            .jq-selectbox {
                &__select {
                    &-text {
                        font-size: 18px;
                        line-height: 150%;
                    }
                }

                &__dropdown {
                    ul {
                        li {
                            font-size: 18px;
                            line-height: 150%;
                        }
                    }
                }
            }
        }
    }

}