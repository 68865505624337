.license {
    &__img {
        margin-bottom: 15px;
        max-width: 67%;    

        img {
            max-width: 100%;
        }
    }

    &__text {
        font-size: 13px;
        line-height: 180%;
        letter-spacing: -0.01em;
        color: @grey-2;
    }
}

@media(min-width: 480px) {
    .license {
        &__img {
            margin-bottom: 21px;
            max-width: 65%;
        }
    }
}

@media(min-width: 640px) {
    .license {
        &__img {
            max-width: 47%;
        }
    }
}

@media(min-width: 960px) {
    .license {
        &__img {
            max-width: 69%;
            margin-bottom: 41px;
        }

        &__text {
            font-size: 16px;
        }
    }
}


@media(min-width: 1200px) {
    .license {
        &__img {
            margin-bottom: 20px;
        }
    }
}