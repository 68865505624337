.text-img {
    padding-top: 57px;

    &__img {
        position: relative;
        width: 288px;
        height: 222px;
        margin-bottom: 30px;

        .bin {
            position: absolute;
            width: 224px;
            bottom: 0;
            left: 0;
        }

        .bin-map {
            position: absolute;
            width: 195px;
            right: 0;
            top: 0;
        }
    }

    &__text {
        padding-left: 5%;
        .list-circle {
            margin-bottom: 0;
        }
    }
}

@media(min-width: 480px) {
    .text-img {
        &__img { 
            width: 378px;
            height: 292px;

            .bin {
                width: 294px;
            }
    
            .bin-map {
                width: 256px;
            }
        }
    }
}

@media(min-width: 640px) {
    .text-img {
        &__img {
            margin-bottom: 42px;
            width: 504px;
            height: 388px;

            .bin {
                width: 392px;
            }
    
            .bin-map {
                width: 342px;
            }
        }
    }
}


@media(min-width: 960px) {
    .text-img {
        padding-top: 0;
        margin-top: -51px;
        display: flex;

        &__text {
            position: relative;
            padding-left: 0;
            margin: 18px 0 0 -47px;
        }

        &__img {
            flex: none; 
            width: 460px;
            height: 356px;

            .bin {
                width: 358px;
            }
    
            .bin-map {
                width: 312px;
            }
        }
    }
}




@media(min-width: 1600px) {
    .text-img {
        margin-top: 0;

        &__img {
            margin-left: -34px;
            margin-bottom: 0;
            width: 627px;
            height: 459px;

            .bin {
                width: 464px;
            }
    
            .bin-map {
                width: 436px;
            }
        }

        &__text {
            padding-left: 0;
            margin: 86px 0 0 -120px;
        }
    }
}


@media(min-width: 1920px) {
    .text-img {
        margin-top: -54px;
        
        &__img {
            max-width: 734px;
        }
    }
}