.project-list {
    &__item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 84px;
        height: 84px;
        border-radius: 50%;

        &-block {
            margin-right: 20px;
        }

        img {
            max-width: 100%;
            max-height: 100%;
            margin-bottom: 0;
        }
    }

    &__container {
        &--mt {
            margin-top: 50px;
        }
    }

    &__name {
        font-size: 16px;
        line-height: 163%;
        color: @main;
        text-decoration: none;
    }

    &--list {
        .project-list {
            &__name {
                color: @grey-2;
            }
        }
    }
}

@media(min-width: 480px) {
    .project-list {
        &__item {
            margin-bottom: 36px;
        }

        &__img {
            width: 120px;
            height: 120px;

            &-block {
                margin-right: 30px;
            }
        }

        &--list {
            .project-list {
                &__img {
                    width: 84px;
                    height: 84px;

                    &-block {
                        margin-right: 20px;
                    }
                }

                &__item {
                    margin-bottom: 20px;
                }
            }
        }
    }
}


@media(min-width: 640px) {
    .project-list {
        padding-left: 5%;

        &--list {
            padding-left: 0;
        }
    }
}


@media(min-width: 960px) {
    .project-list {
        padding: 0;
        display: flex;
        margin-top: 40px;
        flex-wrap: wrap;
        justify-content: center;

        &__container {
            margin-top: 60px;
        }

        &__img {
            width: 140px;
            height: 140px;
            margin: 0 auto;

            &-block {
                text-align: center;
                margin: 0;
                margin-bottom: 15px;
            }
        }


        &__name {
            text-align: center;
        }

        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 33.3%;
            padding: 0 5%;
            &:hover {
                .project-list {
                    &__img {
                        transform: scale(1.07);
                    }
                } 
            }
        }

        &__img {
            transition: transform 0.3s ease;
        }
        
        &--list {
            padding-left: 0;
            flex-direction: column;

            .project-list {
                &__item {
                    width: auto;
                    padding: 0;
                    flex-direction: row;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                &__img {
                    &-block {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

@media(min-width: 1200px) {
    .project-list {

        &__item {
            width: 50%;
        }

        &--list {
            .project-list {
                &__item {
                    padding-right: 65px;
                }

                &__name {
                    text-align: left;
                }
            }
        }
    }
}

@media(min-width: 1600px) {
    .project-list {
        margin-top: 60px;

        &__item {
            width: 33.3%;
            padding: 0 2%;
            margin: 0;
        }

        &__img {
            transition: transform 0.3s ease;
        }

        &__name {
            font-size: 20px;
            line-height: 163%;
        }
    }
}

@media(min-width: 1920px) {
    .project-list {
        &__name {
            max-width: 80%;
        }
    }
}