.main-about {
    &__body {
        position: relative;
        z-index: 2;
        margin-bottom: 38px;
    }

    &__block {
        position: relative;
        z-index: 2;
    }
    
    .ships-line {
        margin: 0 0 -38px 0;

        &::before {
            background-position: bottom;
        }
    }
}

@media(min-width: 480px) {
    .main-about {
        padding: 0 11%;

        .ships-line {
            margin: 0 0 -52px;
        }

        &__body {
            margin-bottom: 77px;
        }

        .numeral {
            margin: 0 -13%;
            width: auto;
        }
    }
}

@media(min-width: 640px) {
    .main-about {
        padding: 0 5%;

        .ships-line {
            margin: 0 0 -52px;
        }

        .numeral {
            margin: 0;
        }

        &__body {
            margin-bottom: 52px;
        }
    }
}

@media(min-width: 960px) {
    .main-about {
        position: relative;
        padding-top: 121px;
        display: flex;

        &__body {
            position: absolute;
            margin-bottom: 0;

            &.is-fixed {
                position: fixed;
            }

            &.is-abs {
                position: absolute;
                top: auto !important;
                bottom: 0;
            }

            &-sticky {
                display: block;
                width: calc(~"100% - 360px");
                float: left;
                position: relative;
            }
        }

        .numeral {
            float: left;
            width: 360px;
            padding-left: 80px;
            flex: none;
            padding-top: 0;
            margin-bottom: 0;
            padding-top: 66px;
            z-index: 2;
            position: relative;

            &__number {
                font-size: 104px;
                line-height: 120%;
            }

            &__item {
                margin-bottom: 50px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &__text {
                font-size: 16px;
                line-height: 138%;
                padding: 0 0 0 40px;
                margin-top: -21px;
            }

            &__col {
                padding: 0;
                margin: 0;
                margin-bottom: 50px;
                
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .ships-line {
            height: 395px;

            &__block {
                position: absolute;
                top: -339px;
            }
        }
    }
}

@media(min-width: 1200px) {
    .main-about {
        padding-top: 0;
        padding-right: 10vw;
        padding-left: 0;

        &__body {
            top: 0;
            padding-right: 40px;

            .decor-title {
                padding-top: 161px;
            }

            &-sticky {
                width: 65vw;
                padding-left: 20vw;
            }
        }

        .ships-line {
            height: 495px;
            margin: 0 0 -60px;

            &__block {
                top: -275px;
            }
        }

        .numeral {
            width: 25vw;
            padding-left: 20px;
            padding-top: 240px;

            &__number {
                font-size: 119px;
                line-height: 120%;
            }

            &__item {
                margin-bottom: 60px;
            }

            &__text {
                padding: 0 0 0 52px;
            }
        }
    }
}

/*
@media(min-width: 1200px) {
    .main-about {
        padding: 138px 0 0 0;
        padding-right: 10vw;

        &__body {
            &-sticky {
                width: 65vw;
                padding-left: 20vw;
            }
        }

        .ships-line {
            margin: 0 0 -60px;

            &__wrap {
                margin-top: -59px;
            }

            &::before {
                left: -55vw;
                width: 2070px;
                height: 495px;
                transform: translateX(0);
                background-image: url(../images/common/ships-back-1200.svg);
            }
        }

        .numeral {
            margin-top: 0;
            width: 25vw;
            padding-left: 20px;

            &__number {
                font-size: 119px;
                line-height: 120%;
            }

            &__item {
                margin-bottom: 60px;
            }

            &__text {
                padding: 0 0 0 52px;
            }
        }
    }
}*/

@media(min-width: 1600px) {
    .main-about {
        p {
            margin: 0 0 57px;
        }

        &__body {
            width: calc(~"100% - 380px");
        }
       
        .numeral {
            width: 380px;

            &__number {
                font-size: 152px;
                line-height: 120%; 
            }

            &__text {
                font-size: 20px;
                line-height: 138%;
                padding: 0 0 0 65px;
                margin-top: -24px;
                max-width: 100%;
            }

            &__item {
                margin-bottom: 87px;
            }
        }
    }
}

@media(min-width: 1920px) {
    .main-about {
        padding-right: 4%;

        &__body {
            padding-right: 100px;
            max-width: 40vw;
        }

        .ships-line {
            height: 412px;
            margin: 0;

            &__block {
                top: -185px;
            }
        }

        .numeral {
            width: 327px;

            &__number {
                font-size: 152px;
                line-height: 120%;
            }
        }
    }
}