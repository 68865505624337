.transition-properties(...) {
    -webkit-transition: @arguments;
    -moz-transition: @arguments;
    -o-transition: @arguments;
    transition: @arguments;
}

.flex-center() {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-center-column() {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.flex-center-vert() {
    display: flex;
    align-items: center;
}