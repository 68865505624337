.form-main {
    &__title {
        font-weight: 600;
        font-size: 17px;
        line-height: 150%;
        margin-bottom: 37px;
        color: @main;
    }

    &__date {
        position: relative;

        .ui-datepicker-trigger {
            position: absolute;
            right: 7px;
            top: 9px;
        }
    }

    &__list {
        position: relative;
    }

    &__line {
        position: relative;
        padding-left: 25px;

        &-body {
            .close {
                cursor: pointer;
                right: -13px;
                top: 43px;

                .svg-icon {
                    width: 10px;
                    height: 10px;

                    use {
                        stroke: #A6A3B4;
                    }
                }
            }
        }
    }

    &__btn {
        width: 100%;
        margin-top: 28px;
    }

    &__number {
        top: 28px;
        position: absolute;
        left: 0;
        letter-spacing: -0.01em;
        color: @grey-4;
        font-weight: 600;
        font-size: 20px;
        line-height: 163%;
    }

    &__block {
        margin-bottom: 16px;
    }
    
    &__subtitle {
        font-weight: 500;
        font-size: 16px;
        line-height: 163%;
        color: @main;
    }

    &__label {
        font-size: 13px;
        line-height: 163%;
        color: fade(@main , 50);
        margin-bottom: 5px;
        transition: color 0.3s ease;
    }

    &__section {
        margin-bottom: 53px;
    }

    &__input {
        width: 100%;
        outline: 0;
        font-weight: 300;
        font-size: 16px;
        line-height: 163%;
        letter-spacing: -0.01em;
        border: 1px solid transparent;
        color: @main;
        padding: 0 10px;
        height: 42px;
        border-radius: 5px;
        background-color: #F4F3F6;
        transition: color 0.3s, border-color 0.3s, background-color 0.3s ease;

        &::-webkit-input-placeholder {font-family: Fira Sans,sans-serif; color: @grey-4;  font-size: 16px;   line-height: 163%;}
        &::-moz-placeholder          {font-family: Fira Sans,sans-serif; color: @grey-4;  font-size: 16px;   line-height: 163%;}
        &:-moz-placeholder           {font-family: Fira Sans,sans-serif; color: @grey-4;  font-size: 16px;   line-height: 163%;}
        &:-ms-input-placeholder      {font-family: Fira Sans,sans-serif; color: @grey-4;  font-size: 16px;   line-height: 163%;}
    
        &.is-active {
            background-color: #fff;
        }
    }
    
    &__field {
        margin-bottom: 10px;

        &.is-error {
            .form-main {
                &__input {
                    border-color:  #FC3838;
                }

                &__label {
                    color: #FC3838;
                }
            }
        }
    }
}

@media(min-width: 480px) {
    .form-main {
        &__field {
            width: 50%;
            padding: 0 8px;

            &.col-66 {
                width: 100%;
            }

            &.col-50 {
                width: 100%;
            }

            &.col-25 {
                width: 50%;
            }

            &.col-100 {
                width: 100%;
            }

            &.col-66-480 {
                width: 66.7%;
            }

            &.col-33 {
                width: 33.3%;
            }
        }

        &__title {
            width: 100%;
            padding: 0 8px;
            margin-bottom: 37px;
        }

        &__block {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin-bottom: 6px;

            .form-main__subtitle {
                width: 100%;
                padding: 0 8px;
            }
        }

        &__section {
            display: flex;
            flex-wrap: wrap;
            margin-left: -8px;
            margin-right: -8px;
            margin-bottom: 39px;
        }

        &__line {
            &-body {
                display: flex;
                flex-wrap: wrap;

                .close {
                    cursor: pointer;
                    right: -13px;
                    top: 44px;

                    .svg-icon {
                        width: 12px;
                        height: 12px;
                    }
                }
            }

            .form-main {
                &__field {
                    &.col-33 {
                        width: calc(~"33.3% + 7px");
                    }
    
                    &.col-66-480 {
                        width: calc(~"66.7% - 7px");
                    }
    
                    &.col-45 {
                        width: 45%;
                    }
                    
                    &.col-55 {
                        width: 55%;
                    }
                }
            }
        }

        &__btn {
            margin-top: 20px;
            padding-left: 8px;
            padding-right: 8px;
        }

        &__list {
            flex: 1 1 0;
            padding: 0 0 0 8px;
        }
    }
}

@media(min-width: 640px) {
    .form-main {
        &__field {
            width: 33.3%;
            
            &.col-50 {
                width: 50%;
            }

            &.col-50-640 {
                width: 50%;
            }
    
            &.col-25 {
                width: 25%;
            }
        }

        &__section {
            margin-bottom: 45px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__line {
            padding-left: 27px;

            &-body {
                margin: 0 0 0 -8px;
            }

            .form-main {
                &__field {
                    width: calc(~"38% - 4.5px");

                    &.col-66-480 {
                        width: calc(~"38% - 4.5px");
                    }

                    &.col-33-640 {
                        width: 33.3%;
                    }


                    &.col-100 {
                        width: 100%;
                    }

                    
                    &.col-50 {
                        width: 50%;
                    }

                    &.col-66-640 {
                        width: 66.7%;
                    }

                    &.col-33 {
                        width: calc(~"24% + 9px");
                    }

                    &.col-25 {
                        width: 25%;
                    }
                }
            }
        }
    }
}

@media(min-width: 960px) {
    .form-main {
        &__title {
            font-size: 19px;
            line-height: 150%;
            margin-bottom: 29px;
        }

        &__block {
            margin-bottom: 1px;
        }

        &__btn {
            padding-left: 39px;
            padding-right: 12px;
        }

        &__subtitle {
            font-size: 18px;
            line-height: 163%;
        }
    }
}

@media(min-width: 1200px) {
    .form-main {
        &__line-body {
            .close {
                &:hover {
                    .svg-icon {
                        use {
                            stroke: @red;
                        }
                    }
                }
            }
        }
    }
}

@media(min-width: 1600px) {
    .form-main {
        &__label {
            font-size: 16px;
            line-height: 163%;
            margin-bottom: 3px;
        }

        &__field {
            padding: 0 12px;
            margin-bottom: 8px;

        }

        &__input {
            padding: 0 17px;
            font-weight: 300;
            font-size: 20px;
            line-height: 163%;

            &::-webkit-input-placeholder {font-family: Fira Sans,sans-serif; color: @grey-4;  font-size: 20px;   line-height: 163%;}
            &::-moz-placeholder          {font-family: Fira Sans,sans-serif; color: @grey-4;  font-size: 20px;   line-height: 163%;}
            &:-moz-placeholder           {font-family: Fira Sans,sans-serif; color: @grey-4;  font-size: 20px;   line-height: 163%;}
            &:-ms-input-placeholder      {font-family: Fira Sans,sans-serif; color: @grey-4;  font-size: 20px;   line-height: 163%;}
        }

        &__title {
            font-size: 24px;
            line-height: 150%;
            margin-bottom: 20px;
        }

        &__section {
            margin-left: -12px;
            margin-right: -12px;
        }

    }
}