.logo {
    display: block;
    width: 97px;

    img {
        display: block;
    }
}

@media(min-width: 480px) {
    .logo {
        width: 133px;
    }
}

@media(min-width: 960px) {
    .logo {
        width: 147px;
    }
}