.main-banner {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    position: relative;
    height: 374px;
    padding-bottom: 65px;
    background-image: url(../images/banner-main.jpg);
    background-position: center;
    background-size: cover;
    margin-top: -42px;
    margin-bottom: -17px;

    &__video {
        position: absolute;
        pointer-events: none;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;

        &::before {
            content:"";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: linear-gradient(0deg, rgba(35, 24, 78, 0.5) 9.67%, rgba(35, 24, 78, 0) 100%);
        }

        video {
            min-width: 102%;
            min-height: 102%;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        transition: opacity 0.6s, z-index .3s ease;

        .container {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: flex-end;
            position: relative;
            z-index: 1;
        }
    }

    &__icon {
        width: 109px;
        height: 109px;
        margin-bottom: 15px;
    }

    &__title {
        font-weight: 500;
        font-size: 28px;
        line-height: 100%;
        color: #FEFEFE;
        letter-spacing: -0.02em;
        text-shadow: 0px 3px 23px rgba(31, 6, 121, 0.5);
    }

    .wave {
        height: 150px;
        position: absolute;
        left: 0;
        bottom: -75px;
    }

    .cert {
        transition: opacity 0.6s ease;
    }
}

@media(min-width: 480px) {
    .main-banner {
        height: 427px;
        margin-top: -48px;
        padding-bottom: 75px;
        margin-bottom: -27px;

        &__icon {
            width: 138px;
            height: 138px;
        }

        &__title {
            font-size: 33px;
            line-height: 100%;
        }

        .wave {
            height: 98px;   
            bottom: 0;
        }
    }
}

@media(min-width: 640px) {
    .main-banner {
        margin-top: -48px;
        padding-bottom: 117px;
        height: 500px;
        margin-bottom: -53px;

        &__icon {
            width: 156px;
            height: 156px;
            margin-bottom: 20px;
        }

        &__title {
            font-size: 40px;
            line-height: 100%;
        }

        .wave {
            height: 195px;
            bottom: -75px;
        }
    }
}

@media(min-width: 960px) {
    .main-banner {
        height: 600px;
        margin-top: -60px;
        padding-bottom: 113px;
        margin-bottom: 15px;

        &__title {
            font-size: 55px;
            line-height: 100%;
            max-width: 685px;
        }

        &__icon {
            width: 195px;
            height: 195px;    
            margin-bottom: 20px;
            margin-left: -9px;
        }

        .wave {
            height: 195px;
            bottom: -75px;
        }
    }
}

@media(min-width: 1200px) {
    .main-banner {
        height: 100vh;
        margin-top: 0;
        padding-bottom: 0;

        &__video {
            position: fixed;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
        }

        &__container {
            z-index: 5;
            position: fixed;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;

            .container {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                height: 100%;    
                padding-bottom: 245px;
               
            }
        }

        &__icon {
            margin-bottom: 34px;
            margin-left: 4px;
        }

        &__title {
            font-size: 55px;
            line-height: 110%;
        }

        .wave {
            z-index: 4;
        }

        &.is-down {
            .main-banner {
                &__container {
                    z-index: 3;
                    opacity: 0;
                }
            }

            .cert {
                opacity: 0;
            }
        }

        &.is-hidden {
            display: none;
        }

        .cert {
            right: 8vw;
        }
    }
}

@media(min-width: 1600px) {
    .main-banner {
        padding-bottom: 229px;

        .container {
            padding-bottom: 230px;
        }

        &__title {
            font-size: 72px;
            line-height: 110%;
            max-width: 876px;
        }

        &__icon {
            width: 253px;
            height: 253px;
            margin-bottom: 28px;
            margin-left: -24px;
        }
        
        .wave {
            bottom: -70px;
        }
    }

    .cert {
        right: 15vw;
 
        &__item {
            width: 90px;
        }
    }
}