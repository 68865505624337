@keyframes radar { 
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(1turn);
    }
    
}

.not-found {
    position: relative;
    padding-top: 38px;
    text-align: center;
    color: @dark-turquoise;
    font-family: 'Kelly Slab', cursive;
    background-image: url(../images/common/404/480-320.svg);
    background-position: center;
    background-size: cover;
    height: 145vw;
    margin-bottom: 25px;
    margin-top: 8px;

    &__img {
        position: absolute;
        left: 5vw;
        width: 80vw;
        height: 80vw;
        bottom: 1.95%;
        animation: radar 5s linear infinite;

        img {
            width: 100%;
            height: 100%;
            transform: scale(1, -1);
        }

    }

    &__title {
        font-size: 60px;
        line-height: 120%;
        margin-bottom: 9px;
    }

    &__text {
        font-size: 12px;
        line-height: 138%;
        letter-spacing: -0.01em;
    }
}

@media(min-width: 480px) {
    .not-found {
        &__title {
            font-size: 91px;
            line-height: 120%;
        }

        &__text {
            font-size: 14px;
            line-height: 138%;
        }
    }
}

@media(min-width: 640px) {
    .not-found {
        width: 461px;
        height: 697px;
        margin-left: auto;
        margin-right: auto;
        background-image: url(../images/common/404/480-640.svg);

        &__title {
            font-size: 106px;
            line-height: 120%;
        }

        &__img {
            left: 26px;
            width: 410px;
            height: 410px;
            bottom: 23px;

            .i-320 {
                display: none;
            }

            .i-480 {
                display: block;
            }
        }

        &__text {
            font-size: 16px;
            line-height: 138%;
        }
    }
}

@media(min-width: 960px) {
    .not-found {
        padding: 0;
        display: flex;
        align-items: center;
        width: 694px;
        height: 457px;
        margin: 0 auto 28px auto;
        background-image: url(../images/common/404/404-desc.png);

        &__body {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 20px;
        }

        &__img {
            left: initial;
            right: 23px;
            height: 410px;
            bottom: 23px;
            width: 410px;

            .i-480 {
                display: none;
            }

            .i-desc {
                display: block;
            }
        }

        &__text {
            text-align: left;
            max-width: 190px;

            span {
                display: block;
            }

            br {
                display: none;
            }
        }
    }
}

@media(min-width: 1200px) {
    .not-found {
        width: 616px;
        height: 406px;
        margin-bottom: 0;

        &__body {
            padding-left: 0;
        }

        &__img {
            left: auto;
            right: 20px;
            height: 365px;
            bottom: 20px;
            width: 365px;
        }
    }
}

@media(min-width: 1600px) {
    .not-found {
        width: 799px;
        height: 526px;

        &__title {
            font-size: 121px;
            line-height: 120%;
        }

        &__text {
            max-width: 230px;
            font-size: 20px;
            line-height: 138%;
        }

        &__img {
            left: auto;
            right: 26px;
            height: 473px;
            bottom: 26px;
            width: 473px;
        }
    }
}

@media(min-width: 1920px) {
    .not-found {
        width: 981px;
        height: 647px;
        background-image: url(../images/common/404/404-big.png);

        &__img {
            left: auto;
            right: 31px;
            height: 581px;
            bottom: 31px;
            width: 581px;
            
            .i-big {
                display: block;
            }
        }

        &__text {
            font-size: 20px;
            line-height: 138%;
            max-width: 249px;
        }
    }
}