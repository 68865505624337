.accordion {
    margin: 0 -5vw;

    &__link {
        display: flex;
        align-items: center;
        margin-left: -16%;
        margin-right: -8%;
        padding: 13px 18px 12px 15px;
        height: 60px;
        font-size: 16px;
        line-height: 163%;
        color: @dark-turquoise;
        cursor: pointer;

        .svg-icon {
            transform: rotate(180deg);
            margin-right: 16px;
            use {
                filL: transparent;
                stroke: @dark-turquoise;
                stroke-width: 2;
            }
        }
    }

    &__body {
        display: none;
        padding: 31px 7% 0 13%;
        
        b {
            margin-bottom: 15px;
            display: block;
            font-weight: 600;
            font-size: 16px;
            line-height: 170%;
            letter-spacing: -0.01em;
            color: #FFFFFF;
        }
    }

    &__item {
        margin-bottom: 3px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 13px 18px 12px 15px;
        background-color: #303158;
        transition: background-color 0.3s ease;
        
        &.is-active {
            background-color: transparent;
            
            .accordion {
                &__control {
                    .svg-icon {
                        transform: rotate(180deg);
                    }
                }

                &__name {
                    color: @dark-turquoise
                }
            }
        }

        &-body {
            display: flex;
            align-items: center;
        }
    }

    &__control {
        .svg-icon {
            transition: transform 0.3s ease;

            use {
                filL: transparent;
                stroke: @dark-turquoise;
                stroke-width: 2;
            }
        }
    }

    &__name {
        font-weight: 600;
        font-size: 16px;
        line-height: 170%;
        color: @turquoise;
        padding: 0 16px;
        transition: color 0.3s ease;
    }

    &__count {
        font-size: 13px;
        line-height: 180%;
        letter-spacing: -0.01em;
        color: @dark-turquoise;

        span {
            display: none;
        }
    }
}

@media(min-width: 960px) {
    .accordion {
        margin: -15px 0 0 0;

        &__count {
            font-size: 16px;
            line-height: 180%;

            span {
                display: inline-block;
            }
        }

        &__link {
            margin-right: 0;
            margin-left: -8%;
        }

        &__body {
            padding: 46px 7% 0 7%;

            b {
                font-size: 20px;
                line-height: 163%;
                margin-bottom: 10px;
            }
        }

        &__name {
            font-size: 18px;
            line-height: 170%;
            padding: 0 21px;
            letter-spacing: -0.01em;
        }

        &__control {
            .svg-icon {
                width: 15px;
            }
        }

        &__main {
            padding: 15px 18px 15px 21px;
        }
    }
}


@media(min-width: 1200px) {
    .accordion {
        &__name {
            font-size: 20px;
            line-height: 163%;
        }

        &__control {
            transition: margin 0.3s ease;
        }

        &__count {
            font-size: 20px;
            line-height: 163%;  
        }

        &__main {
            cursor: pointer;

            &:hover {
                .accordion {
                    &__control {
                        margin: 0 0 -7px 0;
                    }
                }
            }

            &.is-active {
                &:hover {
                    .accordion {
                        &__control {
                            margin: -7px 0 0 0;
                        }
                    }
                }
            }
        }

        &__link {

            .svg-icon {
                transition: margin 0.3s ease;
            }

            &:hover {
                .svg-icon {
                    margin-top: -7px;
                }
            }
        }
    }
}

.vac {
    &__item {
        margin-bottom: 50px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media(min-width: 1200px) {
    .vac {
        &__item {
            margin-bottom: 80px;
        }

        &__main {
            padding-bottom: 20px;
        }
    }
}

@media(min-width: 1600px) {
    .vac {
        &__item {
            ul li {
                font-size: 16px;
                line-height: 163%;
                margin-bottom: 5px;
            }
        }
    }
}