
/*Fira*/
@font-face {
    font-family: 'Fira Sans';
	src: url('../fonts/Fira/FiraSans-Thin.ttf') format("truetype");
	font-weight: 100;
	font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
	src: url('../fonts/Fira/FiraSans-Light.ttf') format("truetype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
	src: url('../fonts/Fira/FiraSans-Regular.ttf') format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
	src: url('../fonts/Fira/FiraSans-Italic.ttf') format("truetype");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('../fonts/Fira/FiraSans-Medium.ttf') format("truetype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('../fonts/Fira/FiraSans-MediumItalic.ttf') format("truetype");
	font-weight: 500;
	font-style: italic;
}

@font-face {
    font-family: 'Fira Sans';
	src: url('../fonts/Fira/FiraSans-SemiBold.ttf') format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
	src: url('../fonts/Fira/FiraSans-SemiBoldItalic.ttf') format("truetype");
	font-weight: 600;
	font-style: italic;
}


@font-face {
    font-family: 'Fira Sans';
	src: url('../fonts/Fira/FiraSans-Bold.ttf') format("truetype");
	font-weight: 700;
	font-style: normal;
}



@font-face {
	font-family: 'Fira Sans Extra Condensed';
	src: url('../fonts/Fira_Sans_Extra_Condensed/FiraSansExtraCondensed-Light.ttf') format("truetype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans Extra Condensed';
	src: url('../fonts/Fira_Sans_Extra_Condensed/FiraSansExtraCondensed-Regular.ttf') format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans Extra Condensed';
	src: url('../fonts/Fira_Sans_Extra_Condensed/FiraSansExtraCondensed-Medium.ttf') format("truetype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans Extra Condensed';
	src: url('../fonts/Fira_Sans_Extra_Condensed/FiraSansExtraCondensed-SemiBold.ttf') format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans Extra Condensed';
	src: url('../fonts/Fira_Sans_Extra_Condensed/FiraSansExtraCondensed-Bold.ttf') format("truetype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans Extra Condensed';
	src: url('../fonts/Fira_Sans_Extra_Condensed/FiraSansExtraCondensed-ExtraBold.ttf') format("truetype");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans Extra Condensed';
	src: url('../fonts/Fira_Sans_Extra_Condensed/FiraSansExtraCondensed-Black.ttf') format("truetype");
	font-weight: 900;
	font-style: normal;
}


@font-face {
	font-family: 'Kelly Slab';
	src: url('../fonts/Kelly/KellySlab-Regular.ttf') format("truetype");
	font-weight: 900;
	font-style: normal;
}