

.item-slider {
    position: relative;

    .slick-arrow {
        display: flex;
        align-items: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        background-color: transparent;
        border: 0;
        padding: 0;

        &.slick-prev {
            left: 10px;
            justify-content: flex-start;
        }

        &.slick-next {
            right: 10px;
            justify-content: flex-end;

            .svg-icon {
                transform: rotate(180deg);
            }
        }

        .svg-icon {
            display: block;
            width: 8px;

            use {
                fill: transparent;
                stroke: #fff;
                stroke-width: 2;
            }
        }
    }
}

@media(min-width: 480px) {
    .item-slider {
        .slick-arrow {
            &.slick-prev {
                left: 5%;
            }

            &.slick-next {
                right: 5%;
            }

            .svg-icon {
                width: 11px;
            }
        }
    }
}

@media(min-width: 960px) {
    .item-slider {
        .slick-arrow {
            
            width: 59px;
            height: 45px;
            z-index: 2;
            bottom: 0;
            top: inherit;
            transform: translateY(0);

            &.slick-prev {
                left: 0;
                background-color: @main;
                justify-content: center;
            }

            &.slick-next {
                left: 59px;
                right: initial;
                background-color: fade(@main, 50);
                justify-content: center;
            }
        }
    }
}

@media(min-width: 1200px) {
    .item-slider {
        .slick-arrow {
            &.slick-next {
                &:hover {
                    .inner-block {
                        animation: rightArrow 1s linear infinite;
                    }
                }
            }

            &.slick-prev {
                &:hover {
                    .inner-block {
                        animation: leftArrow 1s linear infinite;
                    }
                }
            }
        }
    }
}

@media(min-width: 1920px) {
    .item-slider {
        .slick-arrow {
            width: 74px;
            height: 55px;

            &.slick-prev {
                padding-right: 5px;
            }

            &.slick-next {
                left: 74px;
                padding-left: 5px;
            }
        }
    }
}