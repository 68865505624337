.close {
    position: absolute;
    cursor: pointer;
    right: 30px;
    top: 58px;

    .svg-icon {
        display: block;
        width: 14px;
        height: auto;

        use {
            stroke: @turquoise;
        }
    }
}

@media(min-width: 480px) {
    .close {
        right: 55px;
        top: 82px;
    }
}

@media(min-width: 640px) {
    .close {
        right: 1px;
        top: 25px;

        .svg-icon {
            width: auto;
        }
    }
}

