.title {
    color: @dark-turquoise;
    font-size: 20px;
    line-height: 120%;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    font-weight: normal;
    font-family: 'Fira Sans Extra Condensed', sans-serif;
    margin-bottom: 40px;
}

@media(min-width: 480px) {
    .title {
        margin-bottom: 49px;
    }
}

@media(min-width: 960px) {
    .title {
        font-size: 23px;
        line-height: 120%;
        margin-bottom: 67px;
    }
}

@media(min-width: 1600px) {
    .title {
        font-size: 30px;
        line-height: 100%;
        margin-bottom: 70px;
    }
}

@media(min-width: 1920px) {
    .title {
        margin-bottom: 83px;
    }
}