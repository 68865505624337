.preview {
    margin-bottom: -82px;

    &__list {
        display: none;
    }

    &__name {
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
        color: #fff;
        margin-bottom: 9px;
    }

    &__image {
        position: relative;
        width: 100%;
        height: 153px;
        background-size: cover;
        background-position: center;
        background-color: #fff;
        margin-bottom: 23px;


        &::before {
            content:" ";
            position: absolute;
            height: 94px;
            bottom: 0;
            left: 0;
            right: 0;
            background: linear-gradient(0deg,rgba(33,24,68,.5) -3.21%,rgba(33,24,68,0) 93.75%);
        }
    }

    &__item {
        width: 90%;
        margin: 0 auto 82px;
    }

    &__text {
        font-size: 13px;
        line-height: 180%;
        letter-spacing: -0.01em;
        color: @grey-4;
    }
}

@media(min-width: 480px) {
    .preview {
        margin-bottom: -89px;

        &__name {
            font-size: 24px;
            line-height: 130%;
            margin-bottom: 15px;
        }

        &__item {
            width: 79%;
            margin-bottom: 89px;
        }

        &__image {
            height: 229px;
            margin-bottom: 29px;

            &::before {
                height: 140px;
            }
        }
    }
}

@media(min-width: 640px) {
    .preview {
        margin-bottom: -96px;

        &__image {
            height: 305px;

            &::before {
                height: 215px;
            }
        }

        &__item {
            margin-bottom: 96px;
        }
        
        &__name {
            margin-bottom: 19px;
        }
    }
}

@media(min-width: 960px) {
    .preview {
        margin-bottom: -81px;

        &__item {
            width: 100%;
            margin-bottom: 81px;
        }

        &__text {
            font-size: 16px;
            line-height: 180%;
        }

        &__name {
            font-size: 27px;
            line-height: 127%;
            margin-bottom: 24px;
        }

        &__image {
            height: 263px;
            margin-bottom: 33px;

            &::before {
                height: 170px;
            }
        }

        &__col {
            &:last-child {
                padding-top: 132px;
            }
        }
    }
}

@media(min-width: 1200px) {
    .preview {
        &__image {
            height: 257px;

            &::before {
                height: 182px;
            }
        }

        &__col {
            &:last-child {
                padding-top: 90px;
            }
        }
    }
}

@media(min-width: 1600px) {
    .preview {
        margin-bottom: -90px;

        &__item {
            display: flex;
            align-items: center;
            position: relative;
            width: 93%;
            padding-left: 80px;
            margin-bottom: 90px;


            &:nth-child(even) {
                margin-left: 85px;
            }
        }

        &__name {
            font-size: 35px;
            line-height: 113%;
            position: absolute;
            left: 0;
            margin-bottom: 0;
        }

        &__image {
            width: 547px;
            height: 366px;
            margin-bottom: 0;

            &::before {
                background: linear-gradient(89.53deg,rgba(33,24,68,.5) -3.21%,rgba(33,24,68,0) 93.75%);
                width: 359px;
                height: 100%;
                left: -85px;
            }
        }

        &__text {
            padding-left: 66px;
        }

        &__list {
            display: block;
        }

        &__col {
            display: none;

            &:last-child {
                padding-top: 0;
            }
        }
    }
}

