.text-block {
    &__aside {
        padding-top: 101px;
    }
}

@media(min-width: 480px) {
    .text-block {
        &__aside {
            padding-top: 70px;
        }
    }
}

@media(min-width: 640px) {
    .text-block {
        &__aside {
            padding-top: 80px;
        }
    }
}

@media(min-width: 960px) {
    .text-block {
        display: flex;

        &__aside {
            width: 50%;
            padding-top: 0;

            .gallery-slider {
                display: none;
            }
        }

        &__body {
            width: 50%;
            padding-right: 5%;
        }
    }
}