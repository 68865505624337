.menu-block {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    overflow-y: auto;
    padding: 60px 24px 52px;
    background-color: fade(@dark-main , 95%);
    transition: transform 0.3s ease;
    transform: translateX(-100%);
    z-index: 50;
    display: flex;
    flex-direction: column;

    .close {
        position: absolute;
        top: 33px;
        right: 24px;

        .svg-icon {
            use {
                fill: transparent;
                stroke: #A7D9DA;
                stroke-width: 2;
            }
        }
    }

    &.is-active {
        transform: translateX(0);
    }

    .logo {
        display: none;
    }

    &__bottom {
        .lang {
            display: none;
        }
    }
}

@media(min-width: 480px) {
    .menu-block {
        padding: 70px 30px 60px;
    }
}

@media(min-width: 640px) {
    .menu-block {
        padding: 56px 32px 53px;
        width: 320px;

        .close {
            top: 40px;
            right: 40px;
        }
    }
}

@media(min-width: 960px) {
    .menu-block {
        width: 480px;
        padding: 89px 46px;

        .close {
            top: 50px;
            right: 50px;
        }

        &__bottom {
            .callback {
                display: none;
            }
        }
    }
}

@media(min-width: 1200px) {
    .menu-block {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 20%;
        padding: 30px 0 30px 30px;
        background-color: transparent;
        transform: translateX(0);
        overflow-y: initial;
        transition: background-color 0.3s ease;
        
        &.is-hover {
            background-color: @dark-main;
        }

        .close {
            display: none;
        }

        .logo {
            width: 173px;
            display: inline-flex;
        }

        &__bottom {
            .lang {
                display: inline-flex;
            }
        }
    }
}

@media(min-width: 1600px) {
    .menu-block {
        width: 320px;
        overflow: visible;
        padding: 56px 0 56px 60px;
    }
}

@media(min-width: 1920px) {
    .menu-block {

        padding: 45px 0 55px 60px;
    }
}