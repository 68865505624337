.lang {
    .flex-center-vert();
    display: inline-flex;

    &__item {
        .flex-center();
        width: 38px;
        height: 20px;
        font-size: 10px;
        line-height: 163%;
        padding-top: 1px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: @dark-turquoise;
        text-decoration: none;
        transition: color 0.3s ease;

        &.is-active {
            color: @turquoise;
        }
    }
}

@media(min-width: 960px) {
    .lang {
        background-color: fade(@dark-turquoise , 20%);
        border-radius: 12px;

        &__item {
            border-radius: 12px;
            border: 1px solid transparent;

            &.is-active {
                border: 1px solid @dark-turquoise;
            }

            &:hover {
                color: @turquoise;
            }
        }
    }
}