.bread {
    display: none;
    margin-bottom: 70px;
    position: relative;
    z-index: 2;


    ul {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;

        li {
            list-style: none;
            margin-right: 11px;

            &::before {
                display: none;
            }

            &:last-child {
                margin-right: 0;
            }

            a {
                font-size: 13px;
                line-height: 163%;
                font-variant: small-caps;
                font-family: 'Fira Sans Extra Condensed', sans-serif;
                letter-spacing: 0.11em;
                color: @dark-turquoise;
                text-decoration: none;
                position: relative;
                padding-right: 43px;
                transition: color 0.3s ease;
                font-weight: 300;

                &::before {
                    content:" ";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 30px;
                    height: 1px;
                    right: 0;
                    background-color: @dark-turquoise;
                    transition:  background-color 0.3s ease;
                }
            }

            span {
                font-size: 13px;
                line-height: 163%;
                font-family: 'Fira Sans Extra Condensed', sans-serif;
                letter-spacing: 0.11em;
                color: @dark-turquoise;
            }
        }
    }
}

@media(min-width: 960px) {
    .bread {
        display: block;

        ul {
            &>li {
                &>a {
                    &:hover {
                        color: @turquoise;

                        &::before {
                            background-color: @turquoise;
                        }
                    }
                }
            }
        }
    }
}