* {
  box-sizing: border-box;
}

body {
    font-size: 13px;
    line-height: 180%;
    font-family: 'Fira Sans', sans-serif;

}

p {
  font-size: 13px;
  letter-spacing: -0.01em;
  line-height: 180%;
  color: @grey-2;
  margin: 0 0 23px;
}

@media(min-width: 640px) {
  p {
    margin: 0 0 26px;
  }
}


@media(min-width: 960px) {
  p {
    font-size: 16px;
    line-height: 183%;
    margin: 0 0 22px;
  }
}

@media(min-width: 1600px) {
  p {
    font-size: 20px;
    line-height: 168%;
  }
}

.mt60 {
  margin-top: 60px !important;
}

&.is-white {
  color: #fff;
}

img {
    max-width: 100%;
}

.slick, .slick-arrow, input, button {
  outline: 0;
}

button {
  cursor: pointer;
}

a {
  transition: color 0.3s ease;
}

.svg-icon {
  use {
    transition: fill 0.3s, stroke 0.3s ease;
  }
}


.back-yellow {
  background-color: #ECA120;
}

.back-blue {
  background-color: #4127A1;
}


.aside {
  margin-top: 68px;
}

@media(min-width: 480px) {
  .aside {
    margin-top: 55px;
    padding: 0 11%;
  }
}


@media(min-width: 640px) {
  .aside {
    margin-top: 66px;
  }
}

@media(min-width: 960px) {
  .aside {
    width: 30%;
    padding-left: 0;
    padding-right: 0;
    flex: none;
    margin-top: 80px;
  }
}


@media(min-width: 1200px) {
  .aside {
    margin-top: 0;
  }
}

h1, h2, h3, h4 {
  font-weight: normal;
}

h1 {
  font-weight: 500;
  font-size: 28px;
  line-height: 110%;
  letter-spacing: -0.02em;
  color: @turquoise;
  margin: 0;
}

h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #fff;
  margin: 0 0 38px 0;
}

h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  color: #fff;
  margin: 0 0 29px;
}

h4 {
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  color: #FFFFFF;
  margin: 0 0 29px;
}


.section {
  ul {

    padding: 0;
    margin: 0 0 30px 0;

    li {
      list-style: none;
      font-size: 13px;
      line-height: 180%;
      letter-spacing: -0.01em;
      color: #fff;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: " ";
        display: inline-block;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: #fff;
        margin: 0 8px 3px 0;
      }
    }
  }
}

@media(min-width: 480px) {
  h1 {
    font-size: 40px;
    line-height: 110%;
  }

  h2 {
    margin: 0 0 26px;
  }
}

@media(min-width: 640px) {
  h2 {
    margin: 0 0 42px;
  }
}

@media(min-width: 960px) {
  h1 {
    font-weight: 500;
    font-size: 55px;
    line-height: 110%;
    letter-spacing: -0.02em;
  }

  h2 {
    font-size: 27px;
    line-height: 127%;
    margin: 0 0 60px;
  }

  h3 {
    font-size: 22px;
    line-height: 130%;
    margin: 0 0 30px;
  }

  h4 {
    font-size: 19px;
    line-height: 150%;
  }

  .section {
    ul {
      margin: 0 0 24px;

      li {
        font-size: 16px;
        line-height: 163%;
        margin-bottom: 5px;
      }
    }
  }
}

@media(min-width: 1600px) {
  h1 {
    font-size: 72px;
    line-height: 110%;
  }

  h2 {
    font-size: 35px;
    line-height: 113%;
    margin: 0 0 51px;
  }

  h3 {
    font-size: 29px;
    line-height: 130%;
  }

  h4 {
    font-size: 24px;
    line-height: 150%;
    margin-bottom: 29px;
  }

  .section ul li {
    font-size: 20px;
    line-height: 163%;
    margin-bottom: 17px;
  }
}

@media(min-width: 1920px) {

  h2 {
    font-size: 35px;
    line-height: 113%;
  }
}

.subtitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 170%;
  letter-spacing: -0.01em;
  color: @main;
  margin-bottom: 20px;
}

@media(min-width: 1200px) {
  .subtitle {
    font-size: 18px;
    line-height: 170%;
  }
}

@media(min-width: 1600px) {
  .subtitle {
    font-size: 20px;
    line-height: 163%;
  }
}

.text-case {
  img {
    margin-bottom: 40px;
    width: 100%;
  }
}





.level-name {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 7px 20px;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 30px;
  line-height: 163%;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: @grey-2;
  background-color: fade(@grey-4 , 70);
  border-radius: 24px;
  font-family: 'Fira Sans Extra Condensed', sans-serif;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.col-320-20 {
  width: 100%;
}

.col-320-10-10 {
  width: 100%;
}

.col-480-20 {
  width: 100%;
}

.col-480-20 {
  width: 100%;
}

@media(min-width: 640px) {
  .col-640-8 {
    width: 80%;
  }
}

@media(min-width: 960px) {
  .col-960-5 {
    width: 50%;
  }

  .col {
    padding: 0 33px;
  }

  .row {
    margin: 0 -33px;
  }
}

@media(min-width: 1200px) {
  .col {
    padding: 0 38px;
  }

  .row {
    margin: 0 -35px;
  }
}

@media(min-width: 1600px) {
  .col-1600-10 {
    width: 100%;
  }
}



@media(min-width: 1200px) {
  .hidden-1200 {
    display: none !important;
  }
} 
