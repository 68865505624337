.partner {
    margin-top: 75px;

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
        margin: 0 auto;
        margin-bottom: 30px;

        img {
            max-height: 100%;
        }
    }
}

@media(min-width: 480px) {
    .partner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;

        &__item {
            width: 50%;
            padding: 0 3%;
        }
    }
}

@media(min-width: 960px) {
    .partner {
        margin-top: 87px;
        margin-bottom: -35px;

        &__item {
            width: 33.3%;
            padding: 0 5%;
            margin-bottom: 35px;
        }
    }
}

@media(min-width: 960px) {
    .partner {
        margin-bottom: -55px;

        &__item {
            height: 112px;
            padding: 0;
            margin-bottom: 55px;
        }
    }
}

@media(min-width: 1600px) {
    .partner {
        margin-top: 130px;
        padding-right: 0;
        margin-bottom: 49px;

        &__item {
            width: 25%;
            height: 200px;
            padding-right: 8%;
            margin-bottom: 0;
            margin: 0;
        }
    }
}