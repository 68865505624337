.hamburger {
    position: absolute;
    left: 0;
    top: 0;
    .flex-center-column();
    width: 42px;
    height: 100%;
    background-color: @main;

    span {
        display: block;
        width: 20px;
        height: 2px;
        background-color: @turquoise;
        border-radius: 2px;
        margin-bottom: 4px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media(min-width: 480px) {
    .hamburger {
        width: 48px;

        span {
            width: 24px;
            height: 3px;
            margin-bottom: 5px;
        }
    }
}


@media(min-width: 960px) {
    .hamburger {
        width: 60px;
    }
}

@media(min-width: 1200px) {
    .hamburger {
        display: none;
    }
}