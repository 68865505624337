.boat {
    padding-top: 98px;

    &__container {
        .btn-more {
            margin-top: 69px;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        margin-bottom: 54px;

        &:last-child {
            margin-bottom: 0;
        }

        &:nth-child(1) {
            .boat {
                &__shadow {
                    right: -40px;
                    width: 453px;
                    height: 49.79px;
                    background-image: url(../images/main-boat/1/bottom.svg);
                    bottom: 15%;
                }

                &__ship {
                    img {
                        max-width: 276px;
                    }
                }
            }
        }

        &:nth-child(2) {
            .boat {
                &__shadow {
                    right: -117px;
                    width: 583px;
                    height: 64.07px;
                    background-image: url(../images/main-boat/2/bottom.svg);
                    bottom: -14%;
                }
    
                &__ship {
                    img {
                        max-width: 292px;
                    }
                }
            }
        }

        &:nth-child(3) {
            .boat {
                &__shadow {
                    right: -266px;
                    width: 737px;
                    height: 81px;
                    background-image: url(../images/main-boat/3/bottom.svg);
                    bottom: 3%;
                
                }

                &__ship {
                    img {
                        max-width: 75%;
                    }
                }
            }
        }

        &:nth-child(4) {
            .boat {
                &__shadow {
                    width: 737px;
                    height: 81px;
                    right: -411px;
                    background-image: url(../images/main-boat/4/bottom.svg);
                    bottom: -33%;
                }

                &__ship {
                    img {
                        max-width: 105px;
                    }
                }
            }
        }
    }

    &__shadow {
        bottom: 0;
        position: absolute;
        background-position: center;
        background-size: cover;
    }

    &__ship {
        max-width: 320px;
        margin: 0 auto;
        position: relative;
        order: 1;
        margin-bottom: -18px;

        img {
            display: block;
            margin: 0 auto;
        }
    }

    &__info {
        order: 2;
    }

    &__title {
        font-weight: 600;
        font-size: 17px;
        line-height: 150%;
        color: @grey-2;
        text-align: center;
        margin-bottom: 16px;
    }

    &__text {
        text-align: center;

        p {
            margin-bottom: 1px;
        }
    }
}

@media(min-width: 480px) {
    .boat {
        padding-top: 36px;

        &__container {
            .btn-more {
                .btn {
                    width: auto;
                }
            }
        }

        &__item {
            &:nth-child(1) {
                .boat {
                    &__shadow {
                        right: -74px;
                        width: 737px;
                        height: 81px;
                        bottom: 14%;
                        background-image: url(../images/main-boat/1/bottom-480.svg);
                    }

                    &__ship {
                        margin-bottom: -49px;

                        img {
                            max-width: 450px;
                            margin-left: -7px;
                        }
                    }
                }
            }

            &:nth-child(2) {
                 .boat {
                    &__shadow {
                        right: -147px;
                        width: 737px;
                        height: 81px;
                        bottom: -11%;
                        background-image: url(../images/main-boat/2/bottom-480.svg);
                    }

                    &__ship {
                        margin-bottom: -13px;

                        img {
                            max-width: 370px;
                        }
                    }
                }
            }

            &:nth-child(3) {
                .boat {
                   &__shadow {
                        right: -301px;
                        bottom: 3%;
                        background-image: url(../images/main-boat/3/bottom-480.svg);
                   }

                   &__ship {
                        animation-name: fadeInRight;
                        margin-bottom: -24px;

                       img {
                           max-width: 216px;
                       }
                   }
               }
           }

            &:nth-child(4) {
                .boat {
                    &__shadow {
                        bottom: -33%;
                        right: -413px;
                        background-image: url(../images/main-boat/4/bottom-480.svg);
                    }

                    &__ship {
                        margin-bottom: 0;

                        img {
                            max-width: 105px;
                        }
                    }
                }
            }
        }

        &__ship {
            max-width: 432px;
        }
    }
}

@media(min-width: 640px) {
    .boat {
        padding-top: 5PX;

        &__title {
            font-size: 17px;
            line-height: 150%;
        }

        &__container {

        }

        &:nth-child(2) {
            .boat {
                &__title {
                    margin-bottom: -8px;
                }
            }
        }

        &:nth-child(3) {
            .boat {
                &__title {
                    margin-bottom: -24px;
                }
            }
        }

        &:nth-child(4) {
            .boat {
                &__title {
                    margin-bottom: 7px;
                }
            }
        }

        &__ship {
            margin-bottom: -53px;
        }
    }
}

@media(min-width: 960px) {
    .boat {
        padding: 0 5%;
        padding-top: 47px;

        .decor-title {
            padding: 0 5%;
        }

        &__title {
            font-size: 19px;
            line-height: 150%;
            text-align: left;
            margin-bottom: 18px!important;
        }

        &__item {
            align-items: center;
            flex-direction: row;
            margin-bottom: 75px;

            &:nth-child(1) {
                .boat {
                    &__ship {
                        img {
                            margin-left: 0;
                        }
                    }

                    &__shadow {
                        right: inherit;
                        left: -205px;
                    }
                }
            }

            &:nth-child(2) {
                .boat { 
                    &__shadow {
                        right: inherit;
                        left: -205px;
                    }
                }
            }

            &:nth-child(3) {
                .boat {
                    &__shadow {
                        right: inherit;
                        left: -205px;
                    }
                }
            }

            &:nth-child(4) {
                .boat {
                    &__shadow {
                        right: inherit;
                        left: -205px;
                    }
                }
            }
        }

        &__text {
            text-align: left;

            p {
                display: flex;
                align-items: center;

                &::before {
                    content:" ";
                    width: 8px;
                    height: 1px;
                    background: #E0DFE6;
                    margin-right: 21px;
                    display: block;
                }
            }
        }

        &__ship {
            order: 2;
            max-width: inherit;
            flex-grow: 1;
            padding-left: 15px;

            img {
                margin: 0;
            }
        }

        &__info {
            width: 250px;
            order: 1;
        }
    }
}

@media(min-width: 1200px) {
    .boat {
        padding: 0;
        padding-top: 22px;

        .decor-title {
            padding: 0;
        }

        &__container {
            .btn-more {
                margin-top: 75px;
            }
        }

        &__title {
            padding: 0;
            margin-bottom: 25px!important;
        }

        &__item {
            margin-bottom: 45px;
        }

        &__info {
            width: 332px;
        }

        &__ship {
            margin-bottom: 0 !important;
        }
    }
}

@media(min-width: 1600px) {
    .boat {
        padding-top: 0;
        
        &__info {
            width: 314px;
            flex: none;
        }

        &__ship {
            padding-left: 23px;
        }

        &__title {
            font-size: 24px;
            line-height: 150%;
        }

        &__item {
            &:nth-child(1) {
                .boat {
                    &__ship {
                        img {
                            max-width: 900px;
                        }
                    }

                    &__shadow {
                        width: 1474px;
                        height: 162px;
                        left: -416px;
                    }
                }
            }

            &:nth-child(2) {
                .boat {
                    &__ship {
                        img {
                            max-width: 740px;
                        }
                    }

                    &__shadow {
                        width: 1474px;
                        height: 162px;
                        left: -422px;
                        bottom: -12%;
                    }
                }
            }

            &:nth-child(3) {
                .boat {
                    &__ship {
                        img {
                            max-width: 432px;
                        }
                    }

                    &__shadow {
                        width: 1474px;
                        height: 162px;
                        left: -414px;
                        bottom: 4%;
                    }
                }
            }

            &:nth-child(4) {
                .boat {
                    &__ship {
                        img {
                            max-width: 211px;
                        }
                    }
                    
                    &__shadow {
                        width: 1474px;
                        height: 162px;
                        left: -415px;
                        bottom: -32%;
                    }
                }
            }
        }
    }
}

@media(min-width: 1920px) {
    .boat {
        &__info {
            width: 355px;
            margin-right: 58px;
        }
    }
}