.warning {
    display: flex;
    align-items: center;
    padding-left: 2px;

    .svg-icon {
        flex: none;
        margin-right: 12px;
        fill: @grey-4;
    }

    &__text {
        font-size: 12px;
        line-height: 135%;
        color: @grey-4;
        letter-spacing: -0.01em;
    }
}

@media(min-width: 480px) {
    .warning {
        &__text {
            font-size: 14px;
            line-height: 135%;
        }
    }
}

@media(min-width: 1200px) {
    .warning {
        &__text {
            font-size: 16px;
            line-height: 135%;
        }

        .svg-icon {
            width: 34px;
            height: auto;
        }
    }
}