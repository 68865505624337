.form-contact {
    text-align: center;

    &__btn {
        margin-bottom: 25px;

        .btn {
            letter-spacing: 0.21em;
        }
    }

    &__info {
        font-size: 13px;
        line-height: 180%;
        letter-spacing: -0.01em;

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            color: @grey-4;

            a {
                color: #fff;
                margin-left: 5px;
                text-decoration: none;
            }
        }
    }
}

@media(min-width: 960px) {
    .form-contact {
        &__info {
            font-size: 17px;
            line-height: 150%;
        }
    }
}

@media(min-width: 1200px) {
    .form-contact {
        &__info {
            font-size: 20px;
            line-height: 163%;
        }
    }
}