.block-detail {
    background-color: #e0dfe6;

    &.section {
        padding-top: 105px;
        padding-bottom: 90px;
    }

    &__aside {
        display: none;
    }

    h1 {
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
        color: @main;
        margin-bottom: 15px;
        letter-spacing: inherit;
    }

    p {
        color: @main;
    }

    ul {
        li {
            color: @main;
        }
    }

    .list-circle {
        li {
            &::before {
                border-color: fade(@main , 50);
            }
        }
    }

    h3 {
        color: @main;
    }
}

@media(min-width: 480px) {
    .block-detail {
        h1 {
            margin-bottom: 23px;
        }
    }
}

@media(min-width: 640px) {
    .block-detail {
        &.section {
            padding-bottom: 97px;

            h1 {
                margin-bottom: 18px;
            }    

            &+.section {
                &--project {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}

@media(min-width: 960px) {
    .block-detail {
        &.section {
            padding-bottom: 45px;

            h1 {
                font-size: 27px;
                line-height: 127%;
            }

            &+.section {
                &--project {
                    .wave {    
                        top: -40px;
                        z-index: -1;
                    }
                }
            }
        }
    }
}

@media(min-width: 1200px) {
    .block-detail {
        background-color: transparent;
       
        &.section {
            padding-top: 0;
            padding-bottom: 0;

            h1 {
                font-size: 35px;
                line-height: 113%;
            }

            &+.section {
                &--project {
                    .wave {    
                        top: -35px;
                    }
                }
            }
        }

        &__wrap {
            overflow: hidden;
            padding-bottom: 0;
            padding-top: 100px;
            margin-right: -11vw;
        }

        &__body {
            float: left;
            background-color: #e0dfe6;
            width: 50vw;
            padding: 116px 40px 75px;
            margin-top: -100px;
            overflow: hidden;
            z-index: 1;
            position: relative;

            &+div {
                position: static !important;
            }

            &+.block-detail__aside {
                position: relative !important;
            }
        }

        &__aside {
            float: left;
            display: block;
            width: 30vw;
            padding-left: 40px;
            padding-bottom: 75px;

            &-svg {
                position: absolute;
                left: -41px;
                top: -3px;
                z-index: 2;
            }

            h4 {
                font-weight: normal;
                font-size: 20px;
                line-height: 163%;
                margin: 0 0 15px;
            }

            .project-list {
                margin-top: 30px;

                &__container {
                    margin-top: -5px;
                }
            }
        }
    }
}

@media(min-width: 1600px) {
    .block-detail {
        &__body {
            padding-left: 60px;
            padding-bottom: 140px;
            padding-right: 60px;

            &--omega {
                padding-bottom: 60px;
            }
        }
    }
}


