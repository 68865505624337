.client {
    margin-top: 26px;

    &__icon {
        width: 140px;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
    }

    &__item {
        display: flex;
        align-items: center;
        min-height: 140px;
        font-size: 20px;
        line-height: 163%;
        letter-spacing: -0.01em;
        color: @grey-4;
    }
}